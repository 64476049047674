import classNames from "classnames";
import React from "react";
import FieldsetSkeleton from "./FieldsetSkeleton";

export function Fieldset({
  title,
  text,
  content,
  children,
  showDivider = false,
  loaded = true,
  childWrapperClass = "",
  isFullWidth = false,
  ...other
}) {
  if (!loaded) {
    return <FieldsetSkeleton showDivider={showDivider} />;
  }

  return (
    <div data-cy="fieldset" {...other}>
      <div>
        <div className="laptop:grid laptop:grid-cols-3 laptop:gap-6">
          {title || text || content ? (
            <div className="laptop:col-span-1">
              <div className="px-4 tablet:px-0">
                <h3 data-cy="title" className="text-lg font-medium leading-6 text-gray-900">
                  {title}
                </h3>
                <p className="mt-1 text-sm text-gray-600">{text}</p>
                {content}
              </div>
            </div>
          ) : null}

          {children && (
            <div className={classNames(isFullWidth ? "mt-5 laptop:mt-0 laptop:col-span-3" : "mt-5 laptop:mt-0 laptop:col-span-2")}>
              <div className="shadow tablet:rounded-md">
                <div className={`px-4 py-5 bg-white tablet:p-6 ${childWrapperClass}`}>
                  {children}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showDivider && (
        <div className="block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
      )}
    </div>
  );
}
