import { isNullEmptyOrWhitespace } from "./stringUtilities";

/**
 * Redirect to list view
 * @param {Location} location     Location object
 * @param {String|Number} menuId  Menu ID to set in querystring
 * @param {String} formId         Form ID to remove from querystring
 * @returns {{ pathname: string, search: string }}              An object to be passed to history.push
 */
export function redirectToListView(
  location,
  menuId,
  formId = null,
  farmId = null,
  houseId = null
) {
  const searchParams = new URLSearchParams(location.search.substring(1));
  searchParams.set("menuId", menuId.toLowerCase());
  if (isNullEmptyOrWhitespace(formId)) searchParams.delete("formId");

  if (farmId !== null) searchParams.set("farmId", farmId?.toLowerCase());
  if (houseId !== null) searchParams.set("houseId", houseId?.toLowerCase());

  searchParams.delete("parentId");
  searchParams.delete("parentFormId");

  // convert menuId to pathname
  let pathname;

  if (menuId.toLowerCase() === "production") {
    pathname = "/schedule";
  } else if (menuId.toLowerCase() === "dashboards") {
    pathname = "/dashboards";
  } else if (menuId.toLowerCase() === "admindashboards") {
    pathname = "/dashboards/builder";
  } else if (menuId.toLowerCase() === "adminnonconformances") {
    pathname = "/admin/audit/nonconformance";
  } else {
    pathname = "/forms";
  }

  return {
    pathname,
    search: "?" + searchParams.toString(),
  };
}

export function redirectToFormView(
  location,
  formId,
  parentId,
  parentFormId,
  recordId,
  menuId = null,
  farmId = null,
  houseId = null
) {
  const searchParams = new URLSearchParams(location.search.substring(1));
  searchParams.set("formId", formId);

  if (menuId !== null) searchParams.set("menuId", menuId?.toLowerCase());
  if (farmId !== null) searchParams.set("farmId", farmId?.toLowerCase());
  if (houseId !== null) searchParams.set("houseId", houseId?.toLowerCase());

  if (!isNullEmptyOrWhitespace(parentId)) {
    const _parentId = parentId?.toString();
    const _parentFormId = parentFormId?.toString();
    if (isNullEmptyOrWhitespace(parentFormId)) {
      throw new Error("When parentId is set, parentFormId must NOT be null");
    }

    searchParams.set("parentId", _parentId);
    searchParams.set("parentFormId", _parentFormId);
  }

  let view = "edit";

  return {
    pathname: `/forms/${view}${
      !isNullEmptyOrWhitespace(recordId) ? `/${recordId}` : ""
    }`,
    search: "?" + searchParams.toString(),
  };
}
