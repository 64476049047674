import { ChevronRightIcon } from "assets/icons";
import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import ButtonSkeleton from "./ButtonSkeleton";
import PropTypes from "prop-types";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { LOADED_STATUS } from "constants.js";
import ButtonOptions from "./ButtonOptions";
import classNames from "classnames";

const Button = ({
  isFullWidth,
  theme,
  size = "default",
  onClick,
  label,
  children,
  className = "",
  disabled,
  icon,
  iconPosition,
  type = "button",
  options,
  optionsContainerPosition = "type",
  optionsContainerClassName = "",
  optionsClassName = "",
  optionsStyles = "",
  optionsHeading,
  optionProps,
  showOptions,
  onShowOptions,
  onHideOptions,
  showStats,
  showSearch,
  optionsFilteredByMeta,
  ...other
}) => {
  const [show, setShow] = useState(showOptions);
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);
  const [buttonElement, setButtonElement] = useState(null);

  //#region Callbacks

  /**
   * Handle button click
   * @param {Event} ev
   */
  const handleOnClick = (ev) => {
    if (!isNullEmptyOrWhitespace(options)) {
      let result;
      setShow((prevState) => {
        result = !prevState;

        if (result) {
          if (!!onShowOptions) onShowOptions();
        } else {
          if (!!onHideOptions) onHideOptions();
        }

        return result;
      });
    }

    !!onClick && onClick(ev);
  };

  //#endregion

  //#region Side-effects

  /**
   * Toggle setShow when showOptions changes
   */
  useEffect(() => {
    if (isNullEmptyOrWhitespace(options)) return;

    setShow((prevState) =>
      prevState !== showOptions ? showOptions : prevState
    );
  }, [showOptions, options]);

  /**
   * Set loaded
   */
  useEffect(() => {
    setLoaded(LOADED_STATUS.LOADED);
  }, []);

  //#endregion

  //#region helpers

  // Default styles
  const classArr = [
    "inline-flex items-center justify-start border-0 text-sm uppercase",
  ];

  // Addition classes
  if (className) {
    classArr.push(className);
  }

  // Width
  if (isFullWidth) classArr.push("w-full");

  // Size
  if (theme !== "text") {
    if (size === "small") {
      classArr.push("py-1 px-2", "text-xs");
    } else if (size === "large") {
      classArr.push("py-4 px-8", "text-lg");
    } else {
      classArr.push("py-2 px-4");
    }
  }

  // Style
  if (theme === "primary") {
    classArr.push(
      "rounded-sm shadow-sm",
      "bg-primary bg-gradient-to-r from-primary to-secondary border-primary hover:bg-primary-dark text-white",
      "focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10 focus:border-primary focus:text-primary-lighter"
    );
  } else if (theme === "secondary") {
    classArr.push(
      "rounded-sm shadow-sm",
      "bg-secondary-lighter bg-gradient-to-r from-secondary-lightest via-secondary-lightest to-secondary-lighter border border-secondary-lighter shadow-sm font-medium text-secondary",
      "hover:bg-secondary-light",
      "focus:border-secondary focus:text-secondary focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-success-500 focus:ring-opacity-10"
    );
  } else if (theme === "success") {
    classArr.push(
      "rounded-sm shadow-sm",
      "bg-success-50 bg-gradient-to-r from-success-50 via-success-50 to-success-100 border border-success-400 shadow-sm font-medium text-success-600",
      "hover:bg-success-50",
      "focus:border-success-500 focus:text-success-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-success-500 focus:ring-opacity-10"
    );
  } else if (theme === "warning") {
    classArr.push(
      "rounded-sm shadow-sm",
      "bg-warning-50 bg-gradient-to-r from-warning-50 via-warning-50 to-warning-100 border border-warning-400 shadow-sm font-medium text-warning-600",
      "hover:bg-warning-50",
      "focus:border-warning-500 focus:text-warning-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-warning-500 focus:ring-opacity-10"
    );
  } else if (theme === "danger") {
    classArr.push(
      "rounded-sm shadow-sm",
      "bg-danger-50 bg-gradient-to-r from-danger-50 via-danger-50 to-danger-100 border border-danger-400 shadow-sm font-medium text-danger-600",
      "hover:bg-danger-50",
      "focus:border-danger-500 focus:text-danger-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-danger-500 focus:ring-opacity-10"
    );
  } else if (theme === "gray") {
    classArr.push(
      "rounded-sm shadow-sm",
      "bg-gray-100 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 border border-gray-400 shadow-sm font-medium text-gray-600",
      "hover:bg-gray-100 hover:bg-none",
      "focus:border-gray-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-gray-500 focus:ring-opacity-10"
    );
  } else if (theme === "text") {
    classArr.push("hover:text-primary-dark text-primary", "focus:outline-none");
  } else {
    classArr.push(
      "rounded-sm shadow-sm",
      "border border-gray-300 shadow-sm font-medium text-gray-700",
      "hover:bg-gray-50",
      "focus:border-primary focus:text-primary focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10"
    );
  }

  // Disabled
  if (disabled) {
    classArr.push("opacity-75 cursor-not-allowed");
  }

  // Options container position
  // if (optionsContainerPosition === "left") {
  //   optionsClassName += " origin-top-left left-0";
  // } else if (optionsContainerPosition === "bottom") {
  //   optionsClassName += " origin-bottom-left bottom-10 left-0";
  // } else {
  //   optionsClassName += " origin-top-right right-0";
  // }

  //#endregion

  if (loaded === LOADED_STATUS.LOADING) {
    return (
      <ButtonSkeleton
        theme={theme}
        size={size}
        isFullWidth={isFullWidth}
        className={className}
      />
    );
  }

  const WrapperComponent =
    !isNullEmptyOrWhitespace(options) ||
    !isNullEmptyOrWhitespace(optionsContainerClassName)
      ? "div"
      : Fragment;

  const WrapperComponentProps =
    WrapperComponent === "div"
      ? {
          className: classNames(
            optionsContainerClassName,
            !isNullEmptyOrWhitespace(options)
              ? "relative inline-block text-left"
              : null
          ),
        }
      : {};

  return (
    <WrapperComponent {...WrapperComponentProps}>
      <button
        {...other}
        type={type}
        className={classArr.join(" ")}
        onClick={(ev) => handleOnClick(ev)}
        disabled={disabled}
        ref={setButtonElement}
        data-cy="button"
      >
        {iconPosition !== "right" && icon}
        {children}
        {options?.length > 0 && (
          <ChevronRightIcon
            className={`-mr-1 ml-2 h-5 w-5 transition-all transform duration-500 ease-in-out ${
              !show ? "rotate-0 text-gray-300" : "rotate-90 text-primary"
            }`}
          />
        )}
        {iconPosition === "right" && icon}
      </button>

      {!isNullEmptyOrWhitespace(options) &&
        show &&
        ReactDOM.createPortal(
          <ButtonOptions
            className={optionsClassName}
            style={optionsStyles}
            buttonElement={buttonElement}
            title={optionsHeading}
            filters={optionsFilteredByMeta}
            setShow={setShow}
            onHideOptions={onHideOptions}
            showStats={showStats}
            showSearch={showSearch}
            options={options}
            optionProps={optionProps}
          />,
          document.querySelector("#root")
        )}
    </WrapperComponent>
  );
};

Button.propTypes = {
  isFullWidth: PropTypes.bool,
  theme: PropTypes.oneOf([
    "text",
    "primary",
    "secondary",
    "success",
    "warning",
    "danger",
    "black",
    "gray",
  ]),
  size: PropTypes.oneOf(["small", "large", "default"]),
  onClick: PropTypes.func,
  label: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  loaded: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  type: PropTypes.oneOf(["submit", "button"]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]).isRequired,
      onClick: PropTypes.func,
    })
  ),
  optionsContainerPosition: PropTypes.oneOf(["top", "left", "bottom"]),
  optionsClassName: PropTypes.string,
  optionsContainerClassName: PropTypes.string,
};

export { Button };
