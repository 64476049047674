
import { useEffect, useRef } from "react";

const useAbortController = () => {
  const abortControllerRef = useRef(undefined);

  //#region side-effects

  /**
   * Mount/Unmount
   */
   useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //#endregion

  return abortControllerRef.current;
}

export default useAbortController