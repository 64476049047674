import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { linearGradientDef } from "@nivo/core";
import theme from "./theme.json";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
/**
 * Line Chart
 * @see https://nivo.rocks/line/
 * @returns 
 */
export const LineChart = ({ id, keys = [], attrs, settings, data = [] }) => {
  const [areaBaseLineValue, setAreaBaseLineValue] = useState(0);
  const [curveType, setCurveType] = useState("catmullRom");

  useEffect(() => {
    if (!data?.length) return;
    // Build flat array of all data values
    const valuesArray = [];
    for (const dataObj of data) {
      for (const value of dataObj.data) {
        valuesArray.push(value.y);
      }
    }
    //console.log(valuesArray);

    const min = Math.min(...valuesArray);
    //console.log(min);
    setAreaBaseLineValue(min);
  }, [data]);

  useEffect(() => {
    if (!isNullEmptyOrWhitespace(attrs?.curveType))
      setCurveType(attrs.curveType);
  }, [attrs?.curveType]);

  return (
    <div className="relative h-64 w-full">
      <ResponsiveLine
        data={data}
        theme={theme}
        curve={curveType}
        animate={true}
        enableSlices="x"
        enableGridX={false}
        enableGridY={false}
        margin={{
          top: 50,
          right: 20,
          bottom: settings?.showBottomAxisLabel ? 60 : 40,
          left: settings?.showLeftAxisLabel ? 60 : 40,
        }}
        xScale={{
          type: "linear",
          // type: "symlog",
          // type: "log",
          // base: 5,
          min: "auto",
          max: "auto",
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        // yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: settings?.showBottomAxisLabel ? attrs.axis?.bottom?.legend : null,
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 3,
          tickRotation: 0,
          legend: settings?.showLeftAxisLabel ? attrs.axis?.left?.legend : null,
          legendOffset: -50,
          legendPosition: "middle",
        }}
        colors={!!settings?.colors ? Array.from(settings?.colors) : null}
        // pointSymbol={CustomSymbol}
        enablePoints={false}
        pointSize={12}
        pointBorderWidth={1}
        pointBorderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        pointLabelYOffset={-10}
        enablePointLabel={true}
        enableArea={true}
        useMesh={true}
        defs={[
          linearGradientDef("gradientA", [
            { offset: 0, color: "inherit" },
            { offset: 100, color: "inherit", opacity: 0 },
          ]),
        ]}
        fill={[{ match: "*", id: "gradientA" }]}
        areaBaselineValue={areaBaseLineValue}
        sliceTooltip={({ slice, axis, ...other }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <strong>{keys[0].title}</strong>:{" "}
              {slice.points[0].data.xFormatted}
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: "3px 0",
                  }}
                >
                  <strong>
                    {keys.find((k) => k.id === point.serieId).title}
                  </strong>
                  : {point.data.yFormatted}
                </div>
              ))}
              {/* {slice.points.length && (
                <div>
                  Diff:
                  <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                    {slice.points.find((p) =>
                      p.serieId.toLowerCase().startsWith("actual")
                    )?.data.yFormatted /
                      slice.points.find((p) =>
                        p.serieId.toLowerCase().startsWith("expected")
                      )?.data.yFormatted}
                  </div>
                </div>
              )} */}
            </div>
          );
        }}
        legends={[
          {
            dataFrom: "keys",
            data: data.map((d, index) => {
              return {
                id: d.id,
                label: keys.find((k) => k.id === d.id).title,
                color: !!settings?.colors ? settings.colors[index % settings.colors.length] : "red",
              };
            }),
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: -30,
            translateY: -30,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 125,
            itemHeight: 20,
            itemOpacity: 0.85,
            symbolSize: 14,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            toggleSerie: true,
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
