import { useState } from "react";
import PropTypes from "prop-types";
import { Datepicker, TimePicker } from "components/core";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";

const DateTimePicker = ({
  label,
  labelPosition,
  value,
  setValue,
  setValid,
  required = true,
  validate,
  startDate,
  endDate,
  render = true,
  disableCalcTrigger,
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const [date, setDate] = useState(value?.slice(0, 10) ?? undefined);
  const [time, setTime] = useState(value?.slice(12, 16) ?? undefined);
  const [dateValid, setDateValid] = useState(undefined);
  const [timeValid, setTimeValid] = useState(undefined);

  const setDateValue = (value) => {
    setDate(value);
    if (!isNullEmptyOrWhitespace(time)) {
      setValue(`${extractDateFromDateString(value)} ${time}`);
    }
  };

  const setTimeValue = (value) => {
    setTime(value);
    if (!isNullEmptyOrWhitespace(date)) {
      setValue(`${extractDateFromDateString(date)} ${value}:00`);
    }
  };

  const handleSetDateValid = (valid, value) => {
    setDateValid(valid, value);
    setValid(
      valid && timeValid ? true : false,
      isNull(value) || isNull(time)
        ? null
        : `${extractDateFromDateString(value)} ${time}:00`
    );
  };

  const handleSetTimeValid = (valid, value) => {
    setTimeValid(valid, value);
    setValid(
      dateValid && valid ? true : false,
      isNull(date) || isNull(value)
        ? null
        : `${extractDateFromDateString(date)} ${value}:00`
    );
  };

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative w-full"
      } inline-flex space-x-2`}
    >
      <div className="flex-grow">
        <Datepicker
          id={`${label}-date`}
          label={`${label} date`}
          labelPosition={labelPosition}
          setValue={setDateValue}
          value={date}
          setValid={handleSetDateValid}
          startDate={startDate}
          endDate={endDate}
          required={required}
          validate={validate}
          disableCalcTrigger={true}
        />
      </div>
      <div className="">
        <TimePicker
          id={`${label}-time`}
          label={`${label} time`}
          labelPosition={labelPosition}
          setValue={setTimeValue}
          value={time}
          setValid={handleSetTimeValid}
          required={required}
          validate={validate}
          disableCalcTrigger={true}
        />
      </div>
    </div>
  );
}

function extractDateFromDateString(date) {
  return date?.slice(0, 10);
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(["top", "left", "inset"]),
  required: PropTypes.bool,
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  value: PropTypes.string,
  render: PropTypes.bool,
  validate: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
}

export { DateTimePicker };