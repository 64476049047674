import React, { useState, useEffect, useRef, useContext } from "react";
import { PropTypes } from "prop-types";
import { PhotographIcon, XCircleIcon } from "assets/icons";
import { NotificationContext } from "context/NotificationProvider";
import { resizeImage } from "helpers/imageUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

const uploadLimit = 3;
export function Upload({
  id,
  type,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  accept = "image/*",
  multiple = false,
  defaultLabel = "Upload new image",
  render = true,
}) {
  const [label, setLabel] = useState(defaultLabel);
  const [error, setError] = useState(false);
  const [parsedValue, setParsedValue] = useState({
    saved: [],
    pending: [],
    deleting: [],
  });

  const inputFileField = useRef(undefined);

  const { addNotification } = useContext(NotificationContext);

  /**
   * Set parsed value whenever value changes
   */
  useEffect(() => {
    if (typeof value == "string") {
      // No pending files have been selected yet
      const _parsedSaved =
        value.split(",").filter((v) => !isNullEmptyOrWhitespace(v)) ?? [];
      setParsedValue({
        saved: _parsedSaved,
        pending: [],
        deleting: [],
      });
    } else {
      // Add pending values
      setParsedValue({
        saved: value?.saved instanceof Array ? value.saved : [],
        pending: value?.pending?.length > 0 ? [...value.pending] : [],
        deleting: value?.deleting?.length > 0 ? [...value.deleting] : [],
      });
    }
  }, [value]);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);
    if (setValid) setValid(!validationResult, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  const handleDrop = async (ev) => {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile();

          // Validate acceptable filetype
          if (!isValidFileType(file)) {
            addNotification({
              title: "Error",
              theme: "error",
              description: `File ${file.name} file type is invalid.`,
            });
          }

          // Compress file
          let newFile = file;
          if (file.type.startsWith("image/")) {
            newFile = await resizeImage(file, {
              maxWidth: 1920,
              maxHeight: 1920,
              quality: 70,
            });
          }

          // Save new form values
          const pending = [...parsedValue.pending];
          pending.push(newFile);
          const newValue = {
            ...parsedValue,
            pending,
          };
          setValue(newValue);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        const file = ev.dataTransfer.files[i];

        // Validate acceptable filetype
        if (!isValidFileType(file)) {
          addNotification({
            title: "Error",
            theme: "error",
            description: `File ${file.name} file type is invalid.`,
          });
        }

        // Compress file
        let newFile = file;
        if (file.type.startsWith("image/")) {
          newFile = await resizeImage(file, {
            maxWidth: 1920,
            maxHeight: 1920,
            quality: 70,
          });
        }

        // Save new form values
        const pending = [...parsedValue.pending];
        pending.push(newFile);
        const newValue = {
          ...parsedValue,
          pending,
        };
        setValue(newValue);
      }
    }

    // clear file input value
    inputFileField.current.value = null;
  };

  const handleSelect = async (ev) => {
    ev.preventDefault();

    // Use DataTransfer interface to access the file(s)

    for (const [, file] of [...ev.target.files].entries()) {

      // Validate acceptable filetype
      if (!isValidFileType(file)) {
        addNotification({
          title: "Error",
          theme: "error",
          description: `File ${file.name} file type is invalid.`,
        });
      }

      // Compress file
      let newFile = file;
      if (file.type.startsWith("image/")) {
        newFile = await resizeImage(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          quality: 70,
        });
      }

      // Save new form values
      const pending = [...parsedValue.pending];
      pending.push(newFile);
      const newValue = {
        ...parsedValue,
        pending,
      };
      setValue(newValue);
    }

    // clear file input value
    inputFileField.current.value = null;
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();

    setLabel("Drop file to upload");
  };

  const handleDragLeave = (ev) => {
    ev.preventDefault();

    setLabel(defaultLabel);
  };

  const handleClick = (ev) => {
    ev.preventDefault();

    // Open file selection window
    inputFileField.current.click();
  };

  const handleDeletePending = (index) => {
    // Avoid mutation
    const pending = [...parsedValue.pending];
    // Remove from `pending`
    pending.splice(index, 1);
    // Store updated value
    const newValue = {
      ...parsedValue,
      pending,
    };
    setValue(newValue);
  };

  const handleDeleteSaved = (index) => {
    // Avoid mutation
    const saved = [...parsedValue.saved];
    const deleting = [...parsedValue.deleting];
    // Remove from `saved` and push to `deleting`
    const toDelete = saved.splice(index, 1);
    deleting.push(toDelete[0]);
    // Store updated value
    const newValue = {
      ...parsedValue,
      saved,
      deleting,
    };
    setValue(newValue);
  };

  function isValidFileType(file) {
    if (file.type.match("image.*")) return true;

    return "other";
  }

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  // TODO display images from DB

  return (
    <>
      {parsedValue.saved instanceof Array && parsedValue.saved?.length > 0 && (
        <ImageGallery
          className="mb-4"
          images={parsedValue.saved}
          onDelete={handleDeleteSaved}
        >
          <div className="font-medium">Saved images</div>
        </ImageGallery>
      )}
      {parsedValue.pending?.length > 0 && (
        <>
          <div className="font-medium">Pending images</div>
          <ul className="grid grid-cols-3 gap-4">
            {parsedValue.pending.map((file, index) => {
              return (
                <li key={`${file.name}_${index}`} className="relative">
                  <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 border border-gray-300 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden relative">
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      className="object-cover pointer-events-none group-hover:opacity-75 min-w-full"
                    />
                    <button
                      type="button"
                      className="absolute top-2 right-2 focus:outline-none group "
                      onClick={() => handleDeletePending(index)}
                    >
                      <XCircleIcon className="mx-auto h-6 w-6 text-white group-hover:text-primary pointer-events-none" />
                      <span className="sr-only">Delete {file.name}</span>
                    </button>
                  </div>
                  <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                    {file.name}
                  </p>
                  <p className="block text-xs font-medium text-gray-500 pointer-events-none mb-2">
                    Size: {(file.size / 1024).toFixed()} kb
                  </p>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <input
        ref={inputFileField}
        id={id}
        type="file"
        name="file"
        onChange={handleSelect}
        accept={accept}
        multiple={multiple}
        hidden
      />
      {parsedValue.saved.length + parsedValue.pending.length < uploadLimit ? (
        <button
          type="button"
          className="relative flex items-center desktop:block w-full border desktop:border-2 border-gray-300 desktop:border-dashed rounded-lg p-2 desktop:p-8 text-center hover:border-gray-400 focus:outline-none focus:ring-0"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onClick={handleClick}
        >
          <PhotographIcon className="mr-2 desktop:mx-auto h-6 w-6 desktop:h-12 desktop:w-12 text-gray-400 pointer-events-none" />
          <span className="desktop:mt-2 block text-sm font-medium text-gray-500 pointer-events-none">
            {label}
          </span>
        </button>
      ) : null}
      <p className="mt-2 text-xs text-gray-500" id="email-error">
        {parsedValue.saved.length + parsedValue.pending.length}/{uploadLimit}{" "}
        files
      </p>
    </>
  );
}

Upload.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  accept: PropTypes.oneOf(["image/*"]),
  multiple: PropTypes.bool,
  defaultLabel: PropTypes.string,
  render: PropTypes.bool,
};

function ImageGallery({ children, images, onDelete, ...other }) {
  return (
    <div {...other}>
      {children}
      <ul className="grid grid-cols-3 gap-4">
        {images.map((imageUrl, index) => {
          return (
            <li key={`${imageUrl}`} className="relative">
              <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 border border-gray-300 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden relative">
                <img
                  src={imageUrl}
                  alt=""
                  className="object-cover pointer-events-none group-hover:opacity-75 min-w-full"
                />
                <button
                  type="button"
                  className="absolute top-2 right-2 focus:outline-none group "
                  onClick={() => onDelete(index)}
                >
                  <XCircleIcon className="mx-auto h-6 w-6 text-white group-hover:text-primary pointer-events-none" />
                  <span className="sr-only">Delete {imageUrl}</span>
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
