import React from "react";
import { ResponsiveBar } from "@nivo/bar";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const BarChart = ({ id, keys, attrs, settings, data }) => {
  const CustomTick = (tick) => {
    // Allow us to use a uuid Index with a custom label
    const label = tick.value.length === 2 ? tick.value[1] : tick.value;

    return (
      <g transform={`translate(${tick.x},${tick.y})`}>
        <line
          x1="0"
          x2="0"
          y1="0"
          y2="5"
          style={{ stroke: "rgb(119, 119, 119)", strokeWidth: "1" }}
        ></line>
        <text
          x={tick.textX}
          y={tick.textY}
          textAnchor={tick.textAnchor}
          dominantBaseline="middle"
          transform={`rotate(${tick.rotate})`}
          style={{ fontSize: "11px", fontFamily: "sans-serif" }}
        >
          {label}
        </text>
      </g>
    );
  };

  return (
    <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden"></div>
        <div className="relative h-64 w-full">
          <ResponsiveBar
            data={data}
            groupMode="grouped"
            enableLabel={false}
            keys={keys.filter((v, i) => i !== 0).map((k) => k.id)}
            indexBy={(item) => [item.id, item[keys[0].id]]}
            margin={{
              top: 50,
              right: 20,
              bottom: settings?.showBottomAxisLabel ? 60 : 40,
              left: settings?.showLeftAxisLabel ? 60 : 40,
            }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            valueFormat={{ format: "", enabled: false }}
            // colors={{ scheme: "nivo" }}
            colors={!!settings?.colors ? Array.from(settings?.colors) : null}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -40,
              textAnchor: "end",
              renderTick: CustomTick,
              legend: settings?.showBottomAxisLabel
                ? attrs.axis?.bottom?.legend
                : null,
              legendPosition: "middle",
              legendOffset: 42,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: settings?.showLeftAxisLabel
                ? attrs.axis?.left?.legend
                : null,
              legendPosition: "middle",
              legendOffset: -50,
            }}
            legendLabel={(d) => {
              const newLegendLabel = keys.find((k) => k.id === d.id).title;
              return newLegendLabel ?? d.id;
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            // tooltip={(tooltip) =>
            //  {console.log(tooltip)}
            // }
            tooltip={({ id, value, color, indexValue }) => (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                }}
              >
                <strong>{keys[0].title}</strong>: {indexValue[1]}
                <div className="font-medium" style={{ color }}>
                  {keys.find((k) => k.id === id).title}: {value}
                </div>
              </div>
            )}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: -30,
                translateY: -30,
                itemsSpacing: 2,
                itemWidth: 125,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 14,
                symbolShape: "circle",
                toggleSerie: true,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
