import React, { useState, useContext, useEffect, useRef } from "react";
import PageHeader from "components/PageHeader";
// import { useParams } from "react-router-dom";
import {
  startOfLastXDays,
  dateDiffInDays,
  localDate,
} from "helpers/dateUtilities";
import { AppDataContext } from "context/AppDataProvider";
import Breadcrumb from "components/Breadcrumb";
import ScheduleItem from "components/Schedule/ScheduleItem";
import ScheduleItemSkeleton from "components/Schedule/ScheduleItemSkeleton";
import useQuery from "hooks/useQuery";

export default function Schedule() {
  // let { farmId, houseId } = useParams();
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const { farms, schedules, fetchFormValues, updateSchedules, menus } =
    useContext(AppDataContext);

  const [dates, setDates] = useState([]);
  const [farm, setFarm] = useState(undefined);
  const [house, setHouse] = useState(undefined);
  const [placement, setPlacement] = useState(undefined);
  const [entries, setEntries] = useState(undefined);
  const [schedule, setSchedule] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [formIds, setFormIds] = useState([]);

  const abortControllerRef = useRef(undefined);

  //#region side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController(); // Should always come first

      updateSchedules(abortControllerRef.current.signal);

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Set form types
   */
  useEffect(() => {
    if (!menus?.length) return;

    const _formTypes = menus
      .filter((m) => m.MenuOption.toLowerCase() === "production")
      .reduce((result, m) => {
        result.push(
          ...m.Forms.map((f) => ({
            formId: f.FormName.toLowerCase(),
            menuOption: m.MenuOption.toLowerCase(),
          }))
        );
        return result;
      }, []);

    setFormIds(_formTypes);
  }, [menus]);

  // Set production entries
  useEffect(() => {
    if (!farmId || !houseId) return;

    const getProductionData = () => {
      if (!farmId || !houseId || !fetchFormValues || !formIds?.length) return;

      const { signal } = abortControllerRef.current;

      fetchFormValues(farmId, houseId, formIds, signal)
        .then((result) => {
          if (signal.aborted) return;

          setEntries(result?.flat() ?? []);
        })
        .catch((error) => {
          if (signal.aborted) return;

          console.error(error.message);
        });
    };

    getProductionData();
  }, [farmId, houseId, fetchFormValues, formIds]);

  // Set farm
  useEffect(() => {
    if (!farmId || !farms) return;

    setFarm(
      farms.find((farm) => farm.FarmCode.toLowerCase() === farmId.toLowerCase())
    );
  }, [farmId, farms]);

  // Set house
  useEffect(() => {
    if (!houseId || !farm) return;

    setHouse(
      farm.Houses.find((h) => h.HouseNumber.toString() === houseId.toString())
    );
  }, [houseId, farm]);

  // Set placement
  useEffect(() => {
    if (!house) return;

    setPlacement(house.Pens[0]?.Placement);
  }, [house]);

  // Set schedule
  useEffect(() => {
    if (!farmId || !houseId) return;

    setSchedule(
      schedules.filter(
        (schedule) =>
          schedule.FarmCode.toLowerCase() === farmId.toLowerCase() &&
          schedule.HouseNumber.toString() === houseId.toString()
      )
    );
  }, [farmId, houseId, schedules]);

  // Set dates
  useEffect(() => {
    let numDays = dateDiffInDays(
      placement?._DatePlaced?.normalised,
      localDate()
    );
    
    numDays = numDays > 0 ? Math.floor(numDays) : 0;

    if (numDays > 20) {
      numDays = 20;
    }

    setDates(startOfLastXDays(numDays));
  }, [placement]);

  // Set loaded
  useEffect(() => {
    if (entries !== undefined) {
      setLoaded(true);
    }
  }, [entries]);

  // //#endregion

  // Only show 20 days production. Extra day is for previous values
  const datesToRender = dates.length > 20 ? dates.slice(0, 20) : dates;

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
        <PageHeader title="Dates" className="py-6 px-4 sm:px-6 lg:px-8" />
      </div>
      <main className="flex flex-grow flex-col">
        <div className="flex flex-col flex-grow">
          {loaded ? (
            <div className="space-y-4 p-4">
              {datesToRender.map((dateString) => (
                <ScheduleItem
                  key={dateString}
                  date={dateString}
                  placement={placement}
                  entries={entries}
                  loaded={loaded}
                  schedule={schedule}
                  farm={farm}
                  houseId={houseId}
                />
              ))}
            </div>
          ) : (
            <SchedulePageSkeleton />
          )}
        </div>
      </main>
    </div>
  );
}

// Current unused - may be useful in future release
// /**
//  * Get the data status by comparing the local and network data.
//  * @param {object} localData  - The data object stored locally.
//  * @param {object} networkData  - The data object fetched from network.
//  * @returns {DATA_STATUS} - The data status.
//  */
// export function getDataStatus(localData, networkData) {
//   if (!localData) {
//     // No pending data
//     // console.log(localData, networkData, networkData?.Status ?? DATA_STATUS.INCOMPLETE)
//     return networkData?.Status ?? DATA_STATUS.INCOMPLETE;
//   }

//   const networkTimestamp = !!networkData?.LastModified
//     ? dateFromDotNetDateString(networkData.LastModified, {
//         asDate: true,
//         normalise: false,
//       }).getTime()
//     : null;

//     console.log("networkTimestamp", networkTimestamp, networkData?._LastModified.native)

//   if (!networkTimestamp || localData.timestamp > networkTimestamp) {
//     // Pending request is latest data
//     return DATA_STATUS.PENDING;
//   }

//   // Pending data exists but is not the latest
//   // console.log(networkData, networkData?.Status ?? DATA_STATUS.INCOMPLETE)
//   return networkData?.Status ?? DATA_STATUS.INCOMPLETE;
// }

const SchedulePageSkeleton = () => {
  return (
    <>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
    </>
  );
};
