import { CHART_TYPE } from "constants.js";
import { v4 as uuid } from "uuid";
import { isNull } from "helpers/stringUtilities";

/**
 * Format data for specific chart type
 * @param {[]}  data  - Raw form data.
 * @param {{ id: String, type: String }}  chart  - Chart object of chart to format data for.
 */
export const formatChartData = (data, chart) => {
  if (!data?.length) return [];

  // Filter 'age' data by selected datasources
  const allowedDataSourcesSet = new Set();
  for (const key of chart.keys) {
    const dataSource = key.id.includes(":") ? key.id.split(":")[0] : undefined;
    if (!!dataSource) allowedDataSourcesSet.add(dataSource);
  }
  const allowedDataSources = Array.from(allowedDataSourcesSet);

  // Filter data properties by data source
  // const filteredData = data.filter((d) => allowedDataSources.includes(d._dataSource));

  const chartKeys = chart.keys.map((key) => key.id);

  const filteredData = [];
  for (const obj of data) {
    const newProperties = Object.keys(obj)
      ?.filter((key) => chartKeys.includes(key))
      .reduce((res, key) => {
        res[key] = obj[key].value;
        return res;
      }, {});

    // does property have applicable datasource properties
    const hasDataSourceProperty =
      allowedDataSources.length === 0 ||
      Object.keys(newProperties).some((property) =>
        allowedDataSources.includes(property.split(":")[0])
      );

    if (hasDataSourceProperty && Object.keys(newProperties).length > 0) {
      filteredData.push(newProperties);
    }
  }

  if (chart.type === CHART_TYPE.BAR) {
    // BAR CHART
    const newData = filteredData.map((obj) => {
      return {
        ...obj,
        id: uuid(), // react 'key'
      };
    });

    // Remove any values/properties we don't want to show here
    // E.g. newData.forEach((x) => { delete x["Daily Birds Alive"]; });

    return newData;
  } else if (chart.type === CHART_TYPE.LINE) {
    // LINE CHART
    /**
     * Line Chart data, which must conform to this structure:
     * Array<{
     *   id:   string | number
     *   data: Array<{
     *     x: number | string | Date
     *     y: number | string | Date
     *   }>
     * }>
     * @see: https://nivo.rocks/line/
     */
    const newData = [];
    // Index by first key
    const indexBy = chart.keys.length > 0 ? chart.keys[0].id : null;

    filteredData.sort((a, b) => a[indexBy] - b[indexBy]);

    for (const key of chart.keys.filter((k, i) => i > 0)) {
      const flatValues = filteredData.flatMap((x) => ({
        x: x[indexBy],
        y: x[key.id],
      }));
      newData.push({
        id: key.id,
        data: flatValues,
      });
    }

    return newData;
  } else if (chart.type === CHART_TYPE.TABLE) {
    // TABLE CHART
    const newData = filteredData.map((obj) => {
      return {
        ...obj,
        id: uuid(), // react 'key'
      };
    });

    // Remove any values/properties we don't want to show here
    // E.g. newData.forEach((x) => { delete x["Daily Birds Alive"]; });

    return newData;
  } else if (chart.type === CHART_TYPE.METRIC) {
    // METRIC CHART
    const newData = filteredData.map((r) => ({
      ...r,
      id: uuid(), // Ensures that each record set displays on chart, regardless of match index
    }));

    // Fetch only the last record
    return newData.slice(-1);
  } else if (chart.type === CHART_TYPE.TREND) {
    // METRIC CHART
    const newData = filteredData.map((r) => ({
      ...r,
      id: uuid(), // Ensures that each record set displays on chart, regardless of match index
    }));

    // Fetch the last two records
    return newData.slice(-2).reverse();
  }
};

/**
 * Convert JSON object to metrics format
 */
export function extractMetricsFromJsonObjs(jsonObjs) {
  if (isNull(jsonObjs)) return jsonObjs;

  // Build array of distinct metrics
  // It's important to go through full array as some objects contain different properties
  const disallowedKeys = [];
  const metricsMap = new Map();
  for (const obj of jsonObjs) {
    for (const key in obj) {
      // Skip if already set
      const splitKey = key.split(":");
      const keyWithDataSourceRemoved = splitKey[1] ?? key;
      const dataSource = splitKey[0] ?? undefined;
      const isDisallowedKey = disallowedKeys.includes(keyWithDataSourceRemoved);
      if (!isDisallowedKey && !metricsMap.has(key)) {
        metricsMap.set(key, {
          id: key,
          title: obj[key].title,
          dataSource,
        });
      }
    }
  }

  return Array.from(metricsMap.values());
}

export function formatChartAttrs(chart) {
  let _newAttrs = { ...chart.attrs };
  if (chart.type === CHART_TYPE.BAR || chart.type === CHART_TYPE.LINE) {
    _newAttrs = {
      ..._newAttrs,
      axis: {
        ..._newAttrs.axis,
        bottom: {
          ..._newAttrs.axis?.bottom,
          legend: chart.keys.length > 0 ? chart.keys[0].title : null,
        },
        left: {
          ..._newAttrs.axis?.left,
          legend: chart.keys.length > 1 ? chart.keys[1].title : null,
        },
        right: {
          ..._newAttrs.axis?.right,
          legend: chart.keys.length > 2 ? chart.keys[2].title : null,
        },
      },
    };
  }

  return _newAttrs;
}
