import React, { useContext, useState, useEffect } from "react";
import { List, ListItem } from "components/core/Lists";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import PageHeader from "components/PageHeader";
import Breadcrumb from "components/Breadcrumb";
import { AppDataContext } from "context/AppDataProvider";
import { LOADED_STATUS } from "constants.js";
import { redirectToListView } from "helpers/redirectUtilities";

export default function FormSelection() {
  const location = useLocation();

  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);

  let navigate = useNavigate();
  const { menus } = useContext(AppDataContext);

  //#region side-effects

  //#endregion

  //#region event handlers

  const handleClick = (menuId) => {
    // Redirect
    return navigate(redirectToListView(location, menuId));
  };

  useEffect(() => {
    if (menus !== undefined) setLoaded(LOADED_STATUS.LOADED);
  }, [menus]);

  //#endregion

  //#region helpers

  //#endregion

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <Breadcrumb showHome={false} menuRequired={false} />
        <PageHeader title="Menu" className="py-6 px-4 sm:px-6 lg:px-8" />
      </div>
      <main className="flex flex-grow flex-col">
        <div className="flex flex-col flex-grow">
          <List loaded={loaded === LOADED_STATUS.LOADED} theme="striped">
            {menus?.length === 1 ? (
              <Navigate
                to={redirectToListView(
                  location,
                  menus[0].MenuOption.toLowerCase()
                )}
              />
            ) : (
              menus.map((m) => {
                return (
                  <ListItem
                    key={m.MenuOption}
                    onClick={() => handleClick(m.MenuOption.toLowerCase())}
                    aria-label={m.MenuOption}
                  >
                    <p className="font-medium text-primary mr-3">
                      {m.MenuName}
                    </p>
                  </ListItem>
                );
              })
            )}
          </List>
        </div>
      </main>
    </div>
  );
}
