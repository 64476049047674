import classNames from "classnames";
import React from "react";

const Widget = ({
  title,
  showTitle = true,
  actions,
  children,
  className = "",
  size = "",
}) => {
  function getWidgetSizeClass() {
    switch (size) {
      case "small":
        return "col-span-12 laptop:col-span-3";
      case "medium":
        return "col-span-12 laptop:col-span-4";
      case "large":
        return "col-span-12 laptop:col-span-6";
      case "xlarge":
        return "col-span-12 laptop:col-span-9";
      default:
        return "col-span-12";
    }
  }

  const _showHeader = showTitle && title;

  return (
    <div className={classNames(`${className}`, getWidgetSizeClass())}>
      {actions ? (<div className="absolute -top-4 right-0">{actions}</div>) : null}
      <div className="bg-white overflow-hidden rounded-lg shadow min-h-full print:shadow-none print:overflow-visible">
        {_showHeader && (
          <div className="bg-white px-4 py-3 border-b border-gray-200 sm:px-6 print:px-0">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
          </div>
        )}
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Widget;
