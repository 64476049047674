import React from "react";
import { ArrowLeftIcon } from "assets/icons";
import PageHeaderSkeleton from "./PageHeaderSkeleton";
import classNames from "classnames";

export default function PageHeader({
  title,
  subtitle,
  children,
  onClickBack,
  className,
  loaded = true,
}) {
  if (!loaded) {
    return <PageHeaderSkeleton className={className} />;
  }

  return (
    <header
      className={classNames(
        className,
        "bg-white shadow-sm print:p-0 print:m-0 print:shadow-none"
      )}
    >
      {onClickBack && (
        <button onClick={onClickBack} className="mr-4 w-5">
          <ArrowLeftIcon />
        </button>
      )}
      <div className="flex flex-grow flex-row items-center">
        <div className="flex flex-col flex-grow">
          <h1
            data-cy="page-title"
            className="text-3xl font-bold text-gray-900 flex-grow"
          >
            {title}
          </h1>
          {subtitle && (
            <div className="text-base w-full truncate">
              {subtitle}
            </div>
          )}
        </div>
        {children}
      </div>
    </header>
  );
}

// convert nanoseconds to milliseconds
export function nanosecondsToMilliseconds(nanoseconds) {
  return Math.round(nanoseconds / 1000000);
}
