import { useEffect, useCallback } from "react";
import { BreadcrumbItem } from "components/core/Breadcrumbs/Breadcrumb";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbItemFarm({
  farmId,
  menu,
  farm,
  farms,
  menus,
  ...other
}) {
  const navigate = useNavigate();

  //#region Callbacks

  const selectFarm = useCallback(
    (farm) => {
      const searchParams = new URLSearchParams(
        window.location.search?.substring(1)
      );
      searchParams.set("farmId", farm.FarmCode);

      const _selectedHouseId = searchParams.get("houseId");
      if (!isNullEmptyOrWhitespace(_selectedHouseId)) {
        const _existsSelectedHouse = farm.Houses.some(
          (house) => house.HouseNumber.toString() === _selectedHouseId
        );
        if (!_existsSelectedHouse) {
          searchParams.delete("houseId");
        }
      }

      navigate({
        search: "?" + searchParams.toString(),
      });
    },
    [navigate]
  );

  const farmFilteredByMeta = useCallback(() => {
    let result = {};
    if (!isNullEmptyOrWhitespace(menu?.Metadata?.Display?.FarmGroups)) {
      result["Group"] = menu?.Metadata?.Display?.FarmGroups.map((fg) =>
        fg.toUpperCase()
      );
    }

    return result;
  }, [menu?.Metadata?.Display?.FarmGroups]);

  //#endregion

  //#region Side-effects

  /**
   * Auto-set farm
   */
  useEffect(() => {
    if (!isNullEmptyOrWhitespace(farmId)) return;

    if (isNull(farm) && farms.length === 1) {
      // when farm is not set and only a single farm exists
      selectFarm(farms[0]);
    }
  }, [farmId, farm, farms, selectFarm]);

  //#endregion

  //#region Event handlers

  const handleClickBreadcrumbFarm = (farm) => {
    selectFarm(farm);
  };

  //#endregion

  return (
    <BreadcrumbItem
      {...other}
      title={farm?.FarmName ?? "Farms"}
      subtitle={farm ? `${farm?.FarmGroup}, ${farm?.FarmCode}` : "Select farm"}
      showDivider={false}
      loaded={farms.length > 0}
      options={farms?.map((f) => ({
        id: f.FarmCode,
        text: (
          <div>
            <p className="font-medium text-primary">{f.FarmName}</p>
            <p className="text-xs text-gray-600">{f.FarmCode}</p>
          </div>
        ),
        onClick: () => handleClickBreadcrumbFarm(f),
        avatarText: f.FarmGroup,
        meta: { Group: f.FarmGroup },
      }))}
      optionsFilteredByMeta={farmFilteredByMeta()}
      optionsHeading={<div className="text-lg font-medium">Farm</div>}
    />
  );
}
