import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga";
import { ProvideAuth } from "hooks/useAuth";
import { NotificationProvider } from "context/NotificationProvider";
import { AppDataProvider, AppDataContext } from "context/AppDataProvider";
import { NavProvider } from "context/NavProvider";
import Navbar from "components/Navbar";
import LoginPage from "pages/user/login";
import SchedulePage from "pages/SchedulePage";
import MenuPage from "pages/MenuPage";
import FormsPage from "pages/farms/Forms";
import ListPage from "pages/farms/ListPage";
import Notifications from "components/Notifications";
import AppUpdateNotification from "components/AppUpdateNotification";
import Error404Page from "pages/Error404";
import ScrollToTop from "components/ScrollToTop";
import DashboardListPage from "pages/dashboards";
import DashboardBuilderPage from "pages/dashboards/builder";
import DashboardBuilderListPage from "pages/dashboards/builder/ListPage";
import AdminNonConformanceList from "pages/admin/Audit/NonConformance/ListPage";
import { localDate } from "helpers/dateUtilities";
import ReportPage from "pages/farms/Report";
import { ModalProvider } from "context/ModalProvider";
import DebugPage from "pages/Debug";
import BackgroundSyncNotification from "components/BackgroundSyncNotification";

const version = require("../package.json").version;
export const AppVersion = `v${version}`;

export default function App() {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(undefined);

  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdateAvailable",
      handleServiceWorkerUpdateAvailable
    );

    return () =>
      document.removeEventListener(
        "serviceWorkerUpdateAvailable",
        handleServiceWorkerUpdateAvailable
      );
  }, []);

  const handleServiceWorkerUpdateAvailable = (ev) => {
    setWaitingWorker(ev.detail.waiting);
    setUpdateAvailable(true);
  };

  const handleUpdateApp = () => {
    // Call skip waiting to automatically update app.
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setUpdateAvailable(false);
    waitingWorker.addEventListener("statechange", (e) => {
      // Wait for new service worker to become active
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  };

  return (
    <AppDataProvider>
      <NavProvider>
        <NotificationProvider>
          <ModalProvider>
            <ProvideAuth>
              <Notifications />
              <AppUpdateNotification
                show={updateAvailable}
                onClick={handleUpdateApp}
              />
              <BackgroundSyncNotification />
              <Router>
                <ScrollToTop />
                <div
                  id="app"
                  className="bg-gray-100 min-h-screen font-sans text-base flex flex-col overflow-x-hidden print:bg-white"
                >
                  <Navbar />

                  <CustomRoutes />

                  <div className="border-t py-2 flex items-center justify-center">
                    <p className="text-xs text-gray-400">
                      &copy; Unitas Software {localDate().getFullYear()}.
                      {AppVersion}
                    </p>
                  </div>
                </div>
              </Router>
            </ProvideAuth>
          </ModalProvider>
        </NotificationProvider>
      </NavProvider>
    </AppDataProvider>
  );
}

function CustomRoutes() {
  const location = useLocation();
  usePageViews();

  /**
   * Handle service worker update available event
   * without having to refresh the page
   * @see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
   */
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/user/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <MenuPage />
          </RequireAuth>
        }
      />
      <Route
        path="/schedule"
        element={
          <RequireAuth>
            <SchedulePage />
          </RequireAuth>
        }
      />
      <Route
        path="/forms"
        element={
          <RequireAuth>
            <ListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/forms/:view"
        element={
          <RequireAuth>
            <FormViewPage />
          </RequireAuth>
        }
      />
      <Route
        path="/forms/:view/:id"
        element={
          <RequireAuth>
            <FormViewPage />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboards"
        element={
          <RequireAuth>
            <DashboardListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboards/builder/new"
        element={
          <RequireAuth>
            <DashboardBuilderPage />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboards/builder/edit/:id"
        element={
          <RequireAuth>
            <DashboardBuilderPage />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboards/builder"
        element={
          <RequireAuth>
            <DashboardBuilderListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin/audit/nonconformance"
        element={
          <RequireAuth>
            <AdminNonConformanceList />
          </RequireAuth>
        }
      />
      <Route
        path="/debug"
        element={
          <RequireAuth>
            <DebugPage />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export function RequireAuth({ children, ...rest }) {
  const { isSignedIn } = useContext(AppDataContext);
  const location = useLocation();

  if (isSignedIn) {
    return children;
  }

  return <Navigate to="/user/login" replace state={{ from: location }} />;
}

function FormViewPage() {
  const params = useParams();

  if (params.view.toLowerCase() === "report") {
    return <ReportPage />;
  }

  return <FormsPage />;
}

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      // Don't run during tests
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
}
