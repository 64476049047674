import Breadcrumb from "components/Breadcrumb";
import PageHeader from "components/PageHeader";
import { Button, List, ListItem } from "components/core";
import { useNavigate } from "react-router-dom";
import { LOADED_STATUS } from "constants.js";
import useQuery from "hooks/useQuery";
import { useEffect, useState, useContext } from "react";
import { AppDataContext } from "context/AppDataProvider";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useAbortController from "hooks/useAbortController";

export default function ListPage() {
  const navigate = useNavigate();
  // Get query params
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const abortController = useAbortController();
  const signal = abortController?.signal;

  const { farms } = useContext(AppDataContext);

  const [dashboards, setDashboards] = useState([]);
  const [farm, setFarm] = useState(undefined);
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);

  useEffect(() => {
    if (isNullEmptyOrWhitespace(farmId) || isNullEmptyOrWhitespace(farms))
      return;

    const _farm = farms.find(
      (f) => f.FarmCode.toLowerCase() === farmId.toLowerCase()
    );
    setFarm(_farm);
  }, [farmId, farms]);

  useEffect(() => {
    if (!farmId || !houseId || !farm) return;

    // Fetch all dashboards for selected farm
    async function fetchDashboards() {
      try {
        const response = await fetch(
          `/api/dashboards-get?farmGroup=${farm.FarmGroup}&farmId=${farmId}&houseId=${houseId}`,
          {
            signal,
            method: "GET",
          }
        );
        if (response.ok) {
          const responseData = await response.json();

          setDashboards(responseData);
          setLoaded(LOADED_STATUS.LOADED);
        }
      } catch (err) {
        if (signal.aborted) return;
        console.error(err);

        setLoaded(LOADED_STATUS.ERROR);
      }

      return [];
    }

    fetchDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, houseId, farm]);

  //#region Event Handlers

  function handleButtonNewClick() {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    navigate({
      pathname: `/dashboards/builder/new`,
      search: "?" + searchParams.toString(),
    });
  }

  //#endregion

  return (
    <div className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <Breadcrumb
          showHome={false}
          menuRequired={true}
          farmRequired={true}
          houseRequired={true}
        />
        <PageHeader
          title="Dashboards"
          className="py-6 px-4 sm:px-6 lg:px-8 flex flex-row"
        >
          <div className="flex flex-grow justify-end items-center">
            <Button theme="primary" onClick={handleButtonNewClick}>
              New
            </Button>
          </div>
        </PageHeader>
      </div>
      <main className="flex flex-grow flex-col">
        {dashboards?.length > 0 ? (
          <List theme="striped" size="small" loaded={loaded}>
            {dashboards.map((dashboard) => (
              <DashboardListItem key={dashboard.id} dashboard={dashboard} />
            ))}
          </List>
        ) : (
          <div className="flex flex-grow items-center justify-center">
            <div className="flex-grow items-center justify-center text-sm text-center italic">
              <div className="p-2">
                <div>
                  <p>No records found.</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

const DashboardListItem = ({ dashboard, ...other }) => {
  const navigate = useNavigate();

  //#region Handlers

  /**
   * Handle list item click
   */
  const handleClick = (id) => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    navigate({
      pathname: `/dashboards/builder/edit/${id}`,
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  return (
    <ListItem {...other} onClick={() => handleClick(dashboard.id)}>
      <div className="min-w-0 flex-1 flex items-center">
        {/* <ListItemIcon
          dataStatus={formValues?.Status}
          date={formValues.DateApplies}
          dateToday={dateToday}
          loaded={!!formValues?.Status}
        /> */}
        <div className="flex-grow">
          <div className="mb-0.5 text-primary font-medium">
            {dashboard.title}
          </div>

          <div className="text-xs text-gray-500">
            <div className="flex space-x-2">
              {dashboard.farmGroups && (
                <div>Farm group(s): {dashboard.farmGroups}</div>
              )}
              {dashboard.dataSources && (
                <div>Data source(s): {dashboard.dataSources}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};
