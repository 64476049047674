import React from "react";
import Notification from "./core/Notifications/Notification";

export default function AppUpdateNotification({ onClick, show = false }) {
  if (!show) {
    return null;
  }

  return (
    <div>
        <Notification
          key="app-update-notification"
          title="A new version has been released!"
          description={"Click to update to new release."}
          onClick={onClick}
          className="cursor-pointer"
        />
    </div>
  );
}
