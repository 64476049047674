import { useState, useEffect, useContext } from "react";
import {
  dateToString,
  calculateBirdAge,
  dayOfYear,
  startOfWeekFromDate,
  dateAdd,
  localDate,
  localDateToSQLDate,
} from "helpers/dateUtilities";
import { List } from "components/core";
import ListItemDaily from "./ListItemDaily";
import ListItemWeekly from "./ListItemWeekly";
import ListItemEvent from "./ListItemEvent";
import { AppDataContext } from "context/AppDataProvider";
import ListItemMonthly from "./ListItemMonthly";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { DATA_STATUS } from "constants.js";

export default function ScheduleItem({
  date,
  placement,
  entries,
  schedule,
  farm,
  houseId,
  loaded = true,
}) {
  const { menus } = useContext(AppDataContext);

  const [dateString, setDateString] = useState(undefined);
  const [todayEntries, setTodayEntries] = useState(undefined);
  const [birdAge, setBirdAge] = useState(undefined);
  const [dateHatched, setDateHatched] = useState(undefined);
  const [dateDepop, setDateDepop] = useState(undefined);
  const [events, setEvents] = useState({ adhocEvents: [], monthlyEvents: [] });

  // Weekly
  const [weeklyFromDate, setWeeklyFromDate] = useState(undefined);
  const [weeklyToDate, setWeeklyToDate] = useState(undefined);
  const [weeklyEntry, setWeeklyEntry] = useState(undefined);

  const dateToday = localDate();

  //#region side-effects

  // Set weekly schedule
  useEffect(() => {
    const scheduledDayOfWeek =
      menus
        ?.find((m) => m.MenuOption.toLowerCase() === "production")
        ?.Forms?.find((f) => f.FormName.toLowerCase() === "weeklyproduction")
        ?.Schedule;
    if (scheduledDayOfWeek === undefined) {
      setWeeklyFromDate(undefined);
      return;
    }

    // Start date
    let newFromDate = startOfWeekFromDate(date, {
      offset: scheduledDayOfWeek - 1,
    });
    if (newFromDate.getTime() > date.getTime()) {
      // From date exceeds current schedule item date,
      // go back a week
      newFromDate = dateAdd(newFromDate, -1, "weeks");
    }
    setWeeklyFromDate(newFromDate);

    // End date
    const newToDate = dateAdd(newFromDate, 6, "days");
    setWeeklyToDate(newToDate);
  }, [date, menus]);

  // Set date string
  useEffect(() => {
    if (!date) return;

    const result = dateToString(date, {
      includeWeekday: true,
      dateFormat: "long",
    });
    setDateString(result);
  }, [date]);

  // Set today entries
  useEffect(() => {
    if (!date || entries === undefined) return;

    // Set daily entries
    const dailyEntries = findDailyEntries(entries, date);
    setTodayEntries(dailyEntries);
  }, [entries, date]);

  // Set weekly entries
  useEffect(() => {
    if (
      isNull(date) ||
      isNullEmptyOrWhitespace(entries) ||
      isNull(weeklyFromDate) ||
      isNull(weeklyToDate)
    )
      return;

    // Set weekly entry
    const weeklyEntries = findWeeklyEntries(
      entries,
      weeklyFromDate,
      weeklyToDate
    );
    const weeklyEntry =
      weeklyEntries.length > 0 ? weeklyEntries[0] : weeklyEntries;
    setWeeklyEntry(weeklyEntry);
  }, [entries, date, weeklyFromDate, weeklyToDate]);

  // Set date hatched + depop
  useEffect(() => {
    if (!placement) return;

    // Set date hatched
    const dateHatched = placement?._HatchDate?.normalised;
    setDateHatched(dateHatched);
    // Set date depop
    if (placement?.DepopDate !== undefined) {
      const _dateDepop = placement?._DepopDate?.normalised;
      setDateDepop(_dateDepop);
    }
  }, [placement, date]);

  // Set bird age
  useEffect(() => {
    if (!date || !placement) return;

    const hatchDate = placement?._HatchDate?.normalised;
    setBirdAge(
      placement
        ? calculateBirdAge(hatchDate, 0, date)
        : { days: undefined, weeks: undefined }
    );
  }, [date, placement]);

  // Set scheduled events
  useEffect(() => {
    if (!schedule?.length || !birdAge || isNull(date)) return;

    const _dayOfYear = dayOfYear(date);

    // Filter events between start dates
    const adhocEvents =
      schedule.filter(({ Type, StartDays, EndDays }) => {
        const normalisedType = Type.toLowerCase();
        return (
          (normalisedType === "s" ||
            normalisedType === "v" ||
            normalisedType === "t") &&
          birdAge.days >= StartDays &&
          birdAge.days <= EndDays
        );
      }) ?? [];
    const monthlyEvents =
      schedule.filter(({ Type, StartDays, EndDays }) => {
        const normalisedType = Type.toLowerCase();
        return (
          normalisedType === "m" &&
          _dayOfYear >= StartDays &&
          _dayOfYear <= EndDays
        );
      }) ?? [];

    setEvents({ adhocEvents, monthlyEvents });
  }, [schedule, birdAge, date]);

  //#endregion

  //#region Render helpers

  function shouldRenderRecurringEntry(entry, toDate, fromDate) {
    if (isNull(date) || isNull(toDate) || isNull(fromDate)) {
      // No scheduled weekly items
      return false;
    }

    if (isCompletedToday()) {
      // Completed
      // We have a completed entry for today
      // so we know it was completed today,
      // without having to check the date.
      return true;
    } else if (isIncompleteLatestPastEvent()) {
      return true;
    } else if (isOngoingEvent()) {
      return true;
    }
    return false;

    /**
     * Is ongoing event
     * @returns {boolean}
     */
    function isOngoingEvent() {
      return (
        entry?.Status !== DATA_STATUS.COMPLETE &&
        fromDate.getTime() <= dateToday.getTime() &&
        toDate.getTime() >= dateToday.getTime() && // Ongoing event
        localDateToSQLDate(dateToday) === localDateToSQLDate(date) // Only show todays event, compare dates only
      );
    }

    /**
     * Is latest incomplete past event
     * @returns {boolean}
     */
    function isIncompleteLatestPastEvent() {
      return (
        entry?.Status !== DATA_STATUS.COMPLETE &&
        toDate.getTime() <= dateToday.getTime() && // Past event
        toDate.getTime() === date.getTime() // Only show the latest event
      );
    }

    /**
     * Is completed event
     * @returns {boolean}
     */
    function isCompletedToday() {
      return (
        entry?.Status === DATA_STATUS.COMPLETE &&
        entry?._DateApplies.normalised.getTime() === date.getTime()
      );
    }
  }

  // Only render if event date is less than or equal to flock depopulation date
  const isRenderDaily = dateDepop !== undefined ? dateDepop >= date : false;
  const isRenderMonthly = isRenderDaily;
  const isRenderWeekly = shouldRenderRecurringEntry(weeklyEntry, weeklyToDate, weeklyFromDate);

  //#endregion

  return (
    <div
      key={date}
      aria-label={dateString}
      className="bg-white px-3 py-2 border border-gray-200 rounded-md"
      data-cy="schedule-item"
    >
      <div className="grid flex-1 grid-cols-2 gap-2">
        <div className="flex col-span-2 tablet:col-span-2">
          <div className="flex flex-1 items-center">
            <div className="flex-1">
              <div className="flex mb-2">
                <h2 className="flex-1">{dateString}</h2>
                <div>
                  <BirdAge
                    farmGroup={farm?.FarmGroup}
                    birdAge={birdAge}
                    placement={placement}
                    date={date}
                    loaded={loaded && !!farm}
                  />
                </div>
              </div>
              <List theme="striped" size="small">
                {isRenderDaily && (
                  <ListItemDaily
                    key="event-daily"
                    date={date}
                    dateToday={dateToday}
                    todayEntries={todayEntries}
                    loaded={loaded && !!farm}
                    farmId={farm?.FarmCode}
                    houseId={houseId}
                    formId="Production"
                  />
                )}
                {events?.monthlyEvents.map(
                  (event) =>
                    isRenderMonthly && (
                      <ListItemMonthly
                        key={`monthly-production-event`}
                        todayEntries={todayEntries}
                        event={event}
                        date={date}
                        dateToday={dateToday}
                        dateHatched={dateHatched}
                        farmId={farm?.FarmCode}
                        houseId={houseId}
                        loaded={loaded}
                      />
                    )
                )}
                {/* TODO find a solution to enabled/disable certain forms dependant on customer */}
                {isRenderWeekly && (
                  <ListItemWeekly
                    key="event-weekly"
                    entry={weeklyEntry}
                    toDate={weeklyToDate}
                    fromDate={weeklyFromDate}
                    date={date}
                    dateToday={dateToday}
                    dateString={dateString}
                    farmId={farm?.FarmCode}
                    houseId={houseId}
                    formId="WeeklyProduction"
                    loaded={loaded}
                  />
                )}
                {events?.adhocEvents.map((event) => (
                  <ListItemEvent
                    key={`event-${event.FarmScheduleID}`}
                    todayEntries={todayEntries}
                    event={event}
                    date={date}
                    dateToday={dateToday}
                    dateHatched={dateHatched}
                    farmId={farm?.FarmCode}
                    houseId={houseId}
                    loaded={loaded}
                  />
                ))}
              </List>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function findDailyEntries(entries, date) {
  return entries.filter(
    (entry) => entry?._DateApplies?.dateString === localDateToSQLDate(date)
  );
}

function findWeeklyEntries(entries, weeklyFromDate, weeklyToDate) {
  if (
    isNullEmptyOrWhitespace(entries) ||
    isNull(weeklyFromDate) ||
    isNull(weeklyToDate)
  )
    return [];

  const result = entries.filter((entry) => {
    const dateApplies = entry?._DateApplies;
    if (
      entry.FormName.toLowerCase() === "weeklyproduction" &&
      dateApplies?.normalised.getTime() >= weeklyFromDate.getTime() &&
      dateApplies?.normalised.getTime() <= weeklyToDate.getTime()
    ) {
      // Entries exist between start and end of week
      return true;
    }

    return false;
  });

  // sort results by the earliest date
  result.sort((a, b) => a?._DateApplies?.normalised?.getTime() - b?._DateApplies?.normalised?.getTime());

  if (result.length > 1) {
    // Only one record should exist
    // Write to console and continue
    console.error("More than one weekly entries found.");
  }

  return result;
}

function BirdAge({ farmGroup, birdAge, placement, date, loaded = true }) {
  // Display skeleton while loading
  if (!loaded || isNaN(birdAge?.weeks)) {
    return (
      <div className="animate-pulse">
        <div className="h-4 w-8 bg-gray-300 rounded"></div>
      </div>
    );
  }

  return farmGroup.toLowerCase() === "bro"
    ? `Age ${birdAge.days}`
    : `${birdAge.weeks} Wks`;
}
