import { useRef } from "react";
import Breadcrumb from "components/Breadcrumb";
import PageHeader from "components/PageHeader";
import { LOADED_STATUS } from "constants.js";
import useQuery from "hooks/useQuery";
import { useEffect, useState, useContext } from "react";
import { AppDataContext } from "context/AppDataProvider";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
// import useAbortController from "hooks/useAbortController";
import classNames from "classnames";
import Dashboard from "components/Dashboard";
import useDeepCompareEffect from "use-deep-compare-effect";
import { NotificationContext } from "context/NotificationProvider";

export default function DashboardsListPage() {
  // Get query params
  let query = useQuery();
  let farmId = query.get("farmId");
  let houseId = query.get("houseId");

  const abortControllerRef = useRef(undefined);

  const { farms } = useContext(AppDataContext);
  const { addNotification } = useContext(NotificationContext);

  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboardId, setSelectedDashboardId] = useState(undefined);
  const [farm, setFarm] = useState(undefined);
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  useDeepCompareEffect(() => {
    if (isNullEmptyOrWhitespace(farmId) || isNullEmptyOrWhitespace(farms))
      return;

    const _farm = farms.find(
      (f) => f.FarmCode.toLowerCase() === farmId.toLowerCase()
    );
    setFarm(_farm);
  }, [farmId, farms]);

  useEffect(() => {
    if (!farmId || !houseId || !farm?.FarmGroup) return;

    // Fetch all dashboards for selected farm
    async function fetchDashboards() {
      const { signal } = abortControllerRef.current;
      try {
        const response = await fetch(
          `/api/dashboards-get?farmGroup=${farm.FarmGroup}&farmId=${farmId}&houseId=${houseId}`,
          {
            signal,
            method: "GET",
          }
        );

        if (response.ok) {
          const responseData = await response.json();

          setDashboards(responseData);
          if (responseData?.length > 0) {
            setSelectedDashboardId((prevState) =>
              prevState === undefined ? responseData[0].id : prevState
            ); // If undefined, set to first dashboard
          }
          setLoaded(LOADED_STATUS.LOADED);
        } else {
          setLoaded(LOADED_STATUS.ERROR);

          addNotification({
            title: "Error",
            theme: "error",
            description: "An error occurred while fetching dashboard data.",
          });
        }
      } catch (err) {
        if (signal.aborted) return;
        console.error(err);

        setLoaded(LOADED_STATUS.ERROR);

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching dashboard data.",
        });
      }
    }

    // setLoaded(LOADED_STATUS.LOADING);
    fetchDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, houseId, farm?.FarmGroup]);

  return (
    <div className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <Breadcrumb
          showHome={false}
          menuRequired={true}
          farmRequired={true}
          houseRequired={true}
        />
        <PageHeader
          title="Dashboards"
          className="py-6 px-4 sm:px-6 lg:px-8 flex flex-row"
        >
          <div className="flex flex-grow justify-end items-center">
            {/* <ButtonNew
                onClick={handleButtonNewClick}
                options={menu?.Forms}
              /> */}
          </div>
        </PageHeader>
      </div>
      <main className="flex flex-grow flex-col">
        <div className="p-4">
          {loaded === LOADED_STATUS.LOADED ? (
            dashboards?.length > 0 ? (
              <div>
                <Tabs
                  className="mb-4"
                  tabs={dashboards.map((d) => ({
                    name: d.title,
                    id: d.id,
                    current: selectedDashboardId === d.id,
                  }))}
                  onChange={(id) => setSelectedDashboardId(id)}
                ></Tabs>
                <Dashboard id={selectedDashboardId} />
              </div>
            ) : (
              // <List theme="striped" size="small" loaded={loaded}>
              //   {dashboards.map((dashboard) => (
              //     <DashboardListItem dashboard={dashboard} />
              //   ))}
              // </List>
              <div className="flex flex-grow items-center justify-center">
                <div className="flex-grow items-center justify-center text-sm text-center italic">
                  <div className="p-2">
                    <div>
                      <p>No records found.</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="animate-pulse h-6 w-2/3 bg-gray-400 rounded-full"></div>
          )}
        </div>
      </main>
    </div>
  );
}

/**
 * A tab content component type.
 * @typedef {Object}  Tab
 * @property  {String}  name
 * @property  {String}  id
 * @property  {Boolean}  current
 */
/**
 * @param {{ tabs: Tab[], onChange: Function }} props
 * @returns
 */
const Tabs = ({ tabs, onChange, ...other }) => {
  return (
    <div {...other}>
      <div className="tablet:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select dashboard
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          onChange={(ev) => onChange(ev.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden tablet:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={() => onChange(tab.id)}
              className={classNames(
                tab.current
                  ? "text-primary"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-primary" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
};
