import { Button } from "components/core";

const ButtonNew = ({ onClick, options, ...other }) => {
  return (
    <Button
      theme="primary"
      onClick={() =>
        options?.length === 1 && onClick(options[0].FormName.toLowerCase())
      }
      options={
        options &&
        options.map((o) => ({
          id: o.FormName.toLowerCase(),
          text: o.FormTitle,
          onClick: () => onClick(o.FormName.toLowerCase()),
        }))
      }
      {...other}
    >
      New
    </Button>
  );
}

export default ButtonNew;