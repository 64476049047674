import {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import { AppDataContext } from "context/AppDataProvider";
import { NotificationContext } from "context/NotificationProvider";
import useQuery from "hooks/useQuery";
import { useLocation, useParams, Link } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import PageHeader from "components/PageHeader";
import { Alert, Button, Modal } from "components/core";
import Widget from "components/Dashboard/Widget";
import DescriptionList from "components/core/Lists/DescriptionList";
import DescriptionListSkeleton from "components/core/Lists/DescriptionListSkeleton";
import useDeepCompareEffect, {
  useDeepCompareEffectNoCheck,
} from "use-deep-compare-effect";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import {
  getPWAIDfromFormValuesRecord,
  getReadonlyFormValueByFieldType,
} from "helpers/formsUtilities";
import {
  dateToString,
  isDotNetDateString,
  isSQLDateString,
  isUKDateString,
  localDateToSQL,
  sqlDateObjectFromServerTZ,
} from "helpers/dateUtilities";
import { v4 as uuid } from "uuid";
import { redirectToFormView } from "helpers/redirectUtilities";
import { ModalContext } from "context/ModalProvider";
import { BadgeSeverity } from "components/forms/BadgeSeverity";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReportPrintPDF, ReportPrintMode } from "./ReportPrintPDF";

const DEFAULT_UNDEFINED = "null";

export default function ReportPage() {
  const { id } = useParams();
  const query = useQuery();

  const farmId = query.get("farmId");
  const houseId = query.get("houseId");
  const menuId = query.get("menuId");
  const formId = query.get("formId");

  const {
    fetchFormValues,
    fetchFormTemplates,
    farms,
    pageTitle,
    setPageTitle,
    pageSubtitle,
    user,
  } = useContext(AppDataContext);

  const { addNotification } = useContext(NotificationContext);

  const [keys] = useState([
    {
      id: "question",
      title: "Question",
      type: null, // not needed as question will be form field `name`
    },
    {
      id: "status",
      title: "Status",
      type: null, // can be different per question, lookup in formFields
    },
  ]);
  const [farm, setFarm] = useState({});
  const [auditForm, setAuditForm] = useState({});
  const [nonconformanceForm, setNonConformanceForm] = useState(undefined);
  const [completedDate, setCompletedDate] = useState(undefined);
  const [placement, setPlacement] = useState({});
  const [auditData, setAuditData] = useState({});
  const [nonconformanceData, setNonconformanceData] = useState(undefined);
  const [printMode, setPrintMode] = useState(ReportPrintMode.AuditOnlyTable);
  const [isPrinting, setIsPrinting] = useState(false);

  // const reportPrintRef = useRef(undefined);
  const abortControllerRef = useRef(undefined);

  const handlePrint = (printMode) => {
    setPrintMode(printMode);
    setIsPrinting(true);
  };

  //#region Callbacks

  const buildChildOutput = (child, _primaryQuestionImages, datasource) => {
    const _readonlyValue = getReadonlyFormValueByFieldType(
      child.field,
      child.data
    );
    const _fieldType = child.field.FieldType?.toLowerCase();

    if (_fieldType === "up") {
      // Add image to print array
      for (const imgUrl of _readonlyValue) {
        _primaryQuestionImages.push({
          id: uuid(),
          url: imgUrl,
        });
      }

      return {
        id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
        title: child.field.Name,
        value: _readonlyValue,
        type: "images",
        datasource,
      };
    }

    return {
      id: datasource === "audit" ? `cq_${child.field.Name}` : child.field.Ref, //
      title: child.field.Name,
      value: _readonlyValue,
      type: "text",
      datasource,
    };
  };

  const generateNonConformantTableData = useMemo(() => {
    if (auditForm?.FormFields === undefined) {
      return {
        questionStatusLookup: [],
        reportData: [],
        reportSummaryData: [],
      };
    }
    const reportData = [];
    const _printImageData = [];

    // Build primary question lookup(s)
    const _primaryQuestionFieldLookup = [];
    const _childQuestionFieldLookup = [];
    const _nonconformanceFieldLookup = [];

    // Audit form fields
    for (const field of auditForm.FormFields) {
      if (
        !isNullEmptyOrWhitespace(field.QuestionGroup) &&
        field.QuestionGroup === field.Ref
      ) {
        _primaryQuestionFieldLookup[field.Ref.toLowerCase()] = field;
      } else {
        const _refKey = field.Ref.toLowerCase();
        const _questionGroupKey =
          field.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

        if (!_childQuestionFieldLookup[_questionGroupKey]) {
          _childQuestionFieldLookup[_questionGroupKey] = [];
        }
        _childQuestionFieldLookup[_questionGroupKey][_refKey] = field;
      }
    }

    // Non-conformance form fields
    for (const field of nonconformanceForm?.FormFields) {
      const _refKey = field.Ref.toLowerCase();
      for (const primaryQuestionRef of Object.keys(
        _primaryQuestionFieldLookup
      )) {
        const _questionGroupKey = primaryQuestionRef;

        if (!_nonconformanceFieldLookup[_questionGroupKey]) {
          _nonconformanceFieldLookup[_questionGroupKey] = [];
        }
        _nonconformanceFieldLookup[_questionGroupKey][_refKey] = field;
      }
    }

    //Build set of non-conformant primary question refs
    const _nonConformancePrimaryQuestionLookup = new Map(); //Non-conformant primary question field and data for easy lookup
    const _childQuestionLookup = []; //Child field and data for easy lookup
    const _nonconformanceQuestionLookup = []; //Non-Conformance field and data for easy lookup
    // const _childQuestionLookup = new Map(); //Child field and data for easy lookup
    const questionStatusLookup = new Map();

    const auditDataPenValues = auditData?.PenValues ?? [];
    for (const pen of auditDataPenValues) {
      for (const pv of pen.Values) {
        const _primaryQuestionField =
          _primaryQuestionFieldLookup[pv.Ref?.toLowerCase()];

        if (_primaryQuestionField) {
          //Primary question
          const listOption = _primaryQuestionField.ListOptions?.find(
            (lo) => lo.Value === pv.Value
          );

          //Build list of value:counter array
          if (listOption !== undefined) {
            const _existingEntry =
              questionStatusLookup.get(listOption.Text) ?? 0;

            questionStatusLookup.set(listOption.Text, {
              id: _existingEntry.id ?? uuid(),
              text: _existingEntry.text ?? listOption.Text,
              severity: _existingEntry.severity ?? listOption.SeverityColour,
              value: (_existingEntry.value ?? 0) + 1,
            });
          }

          _nonConformancePrimaryQuestionLookup.set(pv.Ref.toLowerCase(), {
            field: _primaryQuestionField,
            data: pv,
          });
        } else {
          //Child

          const _refKey = pv.Ref?.toLowerCase();
          const _questionGroupKey =
            pv.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

          const _childQuestionField =
            _childQuestionFieldLookup[_questionGroupKey]?.[_refKey];

          if (!_childQuestionField) continue;

          if (!_childQuestionLookup[_questionGroupKey]) {
            _childQuestionLookup[_questionGroupKey] = [];
          }

          // Format values such as Dates and Numbers
          // Convert dates
          if (
            isSQLDateString(pv.Value) ||
            isDotNetDateString(pv.Value) ||
            isUKDateString(pv.Value)
          ) {
            const convertedValue = sqlDateObjectFromServerTZ(pv.Value);
            pv.Value = localDateToSQL(convertedValue.normalised, {
              includeOffset: false,
            });
          }

          _childQuestionLookup[_questionGroupKey].push({
            field: _childQuestionField,
            data: pv,
          });
        }
      }
    }

    // Non-conformance questions
    const nonConformanceDataPenValues = nonconformanceData?.PenValues ?? [];
    for (const pen of nonConformanceDataPenValues) {
      for (const pv of pen.Values) {
        const _refKey = pv.Ref?.toLowerCase();
        const _questionGroupKey =
          pv.QuestionGroup?.toLowerCase() ?? DEFAULT_UNDEFINED;

        const _nonconformanceQuestionField =
          _nonconformanceFieldLookup[_questionGroupKey]?.[_refKey];

        if (!_nonconformanceQuestionField) continue;

        if (!_nonconformanceQuestionLookup[_questionGroupKey]) {
          _nonconformanceQuestionLookup[_questionGroupKey] = [];
        }

        // Format values such as Dates and Numbers
        // Convert dates
        if (
          isSQLDateString(pv.Value) ||
          isDotNetDateString(pv.Value) ||
          isUKDateString(pv.Value)
        ) {
          const convertedValue = sqlDateObjectFromServerTZ(pv.Value);
          pv.Value = localDateToSQL(convertedValue.normalised, {
            includeOffset: false,
          });
        }

        _nonconformanceQuestionLookup[_questionGroupKey].push({
          field: _nonconformanceQuestionField,
          data: pv,
        });
      }
    }

    const primaryQuestions =
      _nonConformancePrimaryQuestionLookup.values() ?? [];
    for (const primaryQuestion of primaryQuestions) {
      const _questionGroupKey =
        primaryQuestion.field.Ref?.toLowerCase() ?? DEFAULT_UNDEFINED;

      const isNcnPrimaryQuestion =
        primaryQuestion.field.ListOptions?.some((option) =>
          option.hasOwnProperty("IsNcn")
        ) ?? false;

      const hasCompletedNonConformance =
        _nonconformanceQuestionLookup[_questionGroupKey]?.length > 0;

      const _primaryQuestionImages = [];

      const listOptions = primaryQuestion.field.ListOptions;
      const selectedListOption = listOptions?.find(
        (lo) => lo.Value === primaryQuestion.data.Value
      );
      const hasNonConformance =
        isNcnPrimaryQuestion && selectedListOption !== undefined
          ? selectedListOption.IsNcn?.toString().toLowerCase() === "true"
          : false;
      const value = !isNullEmptyOrWhitespace(listOptions)
        ? primaryQuestion.data.Text
        : primaryQuestion.data.Value;
      const statusColor = selectedListOption?.SeverityColour;

      reportData.push({
        id: `${primaryQuestion.field.Ref}`,
        title: primaryQuestion.field.Name,
        children: [
          {
            id: "answer",
            title: "Answer",
            value: value,
            statusColor,
            type: !isNullEmptyOrWhitespace(listOptions) ? "status" : "text",
            datasource: "audit",
          },
          primaryQuestion.field.Section && {
            id: "section",
            title: "Section",
            value: primaryQuestion.field.Section,
            type: "text",
            datasource: "audit",
          },
          ...(_childQuestionLookup[_questionGroupKey] ?? []).map((child) => {
            return buildChildOutput(child, _primaryQuestionImages, "audit");
          }),
          ...(_nonconformanceQuestionLookup[_questionGroupKey] ?? []).map(
            (child) => {
              return buildChildOutput(
                child,
                _primaryQuestionImages,
                "nonconformance"
              );
            }
          ),
          hasNonConformance &&
            !hasCompletedNonConformance && {
              id: "alert",
              title: "Non-conformances",
              value: (
                <Alert theme="warning">
                  Non-conformance form has NOT been completed.
                </Alert>
              ),
              type: "alert",
              datasource: "audit",
            },
        ],
        section: primaryQuestion.field.Section?.toLowerCase(),
        hasNonConformance,
      });

      _primaryQuestionImages?.length > 0 &&
        _printImageData.push({
          id: `${primaryQuestion.field.Ref}`,
          title: primaryQuestion.field.Name,
          value: _primaryQuestionImages,
          type: "images",
          datasource: "audit",
          hasNonConformance,
        });
    }

    return {
      questionStatusLookup: Array.from(questionStatusLookup.values()).sort(
        (a, b) => b.severity - a.severity
      ),
      reportData,
      printImages: _printImageData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auditData?.PenValues,
    auditForm?.FormFields,
    formId,
    id,
    nonconformanceData?.PenValues,
    nonconformanceForm?.FormFields,
  ]);

  //#endregion

  // const [instance, updateInstance] = usePDF({
  //   document: (
  //     <ReportPrintPDF
  //       farm={farm}
  //       auditForm={auditForm}
  //       completedDate={completedDate}
  //       auditor={auditData?.CreatedBy ?? user?.FullName}
  //       nonconformanceCounts={
  //         generateNonConformantTableData.questionStatusLookup
  //       }
  //       reportData={generateNonConformantTableData.reportData}
  //       printImages={generateNonConformantTableData.printImages}
  //       printMode={printMode}
  //     />
  //   ),
  // });

  //#region side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();
      setPageTitle("Report");

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Set farm
   */
  useDeepCompareEffect(() => {
    if (isNull(farms) && isNullEmptyOrWhitespace(farmId)) return;

    const _farm = farms.find(
      (farm) => farm.FarmCode.toLowerCase() === farmId.toLowerCase()
    );
    setFarm((prevState) => _farm ?? prevState);
  }, [farmId, farms]);

  /**
   * Fetch form fields
   */
  useEffect(() => {
    if (!farm?.FarmGroup || !formId) return;

    const { signal } = abortControllerRef.current;

    setAuditForm(null);

    fetchFormTemplates(farm.FarmGroup, signal)
      .then((data) => {
        if (signal.aborted) return;

        // Get audit form by formId
        const auditForm = data.find(
          (f) => f.FormName.toLowerCase() === formId.toLowerCase()
        );
        const nonconformanceForm = data.find(
          (f) => f.FormName.toLowerCase() === "nonconformance"
        );

        if (!auditForm) {
          // Don't continue, form not found
          return;
        }

        // Filter form fields by FarmType
        if (farm?.FarmType) {
          auditForm.FormFields = auditForm.FormFields.filter(
            (ff) =>
              isNullEmptyOrWhitespace(ff.FarmType) ||
              ff.FarmType.split(",").some(
                (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
              )
          );
          if (nonconformanceForm?.FormFields) {
            nonconformanceForm.FormFields =
              nonconformanceForm.FormFields.filter(
                (ff) =>
                  isNullEmptyOrWhitespace(ff.FarmType) ||
                  ff.FarmType.split(",").some(
                    (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
                  )
              );
          }
        }

        if (nonconformanceForm?.FormFields) {
          //TODO: filter by Display.edit.position === null
          nonconformanceForm.FormFields = nonconformanceForm.FormFields.filter(
            (ff) => ff.Display?.toLowerCase() !== "h"
          );
        }

        setPageTitle(`${auditForm.FormTitle} - Report`);
        setNonConformanceForm(nonconformanceForm ?? null);
        setAuditForm(auditForm);

        return;
      })
      .catch((error) => {
        if (signal.aborted) return;
        console.error(error.message);
      });
  }, [
    farm?.FarmGroup,
    farm?.FarmType,
    fetchFormTemplates,
    formId,
    setPageTitle,
  ]);

  /**
   * Fetch form values
   */
  useDeepCompareEffectNoCheck(() => {
    if (!formId || !farmId || !houseId || !menuId || !farms.length) return;

    const { signal } = abortControllerRef.current;

    const _formIds = [{ formId: formId, menuOption: menuId }];
    _formIds.push({
      formId: "nonconformance",
      menuOption: menuId,
    });

    // Fetch form values
    fetchFormValues(farmId, houseId, _formIds, signal)
      .then((res) => {
        if (signal.aborted) return;

        // Audit records
        const auditRecords = res[0];
        const currentRecord = auditRecords.find(
          (record) =>
            record.ID?.toString() === id ||
            getPWAIDfromFormValuesRecord(record) === id
        );
        setAuditData(currentRecord);

        // Non-Conformance record
        const nonconformanceRecords = res[1];
        const currentNonconformanceRecord = nonconformanceRecords.find(
          (record) =>
            record.ParentPWAID === getPWAIDfromFormValuesRecord(currentRecord)
        );

        setNonconformanceData(currentNonconformanceRecord ?? null);

        const _completedDate = currentRecord._DateApplies.normalised;
        setCompletedDate(_completedDate);
      })
      .catch((error) => {
        if (signal.aborted) return;

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching the form data.",
        });

        console.error(error.message);
      });
  }, [formId, menuId, farmId, houseId, id, farms]);

  /**
   * Set bird age
   */
  useDeepCompareEffect(() => {
    if (
      isNullEmptyOrWhitespace(houseId) ||
      isNullEmptyOrWhitespace(farm) ||
      isNullEmptyOrWhitespace(completedDate?.toString())
    )
      return;

    const _placement = farm.Houses.find(
      (house) => house.HouseNumber.toString() === houseId.toString()
    )?.Pens?.find((pen) => pen.PenNumber.toString() === "1")?.Placement;
    setPlacement(_placement);
  }, [completedDate, farm, houseId]);

  //#endregion

  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className="relative z-20">
          <Breadcrumb
            key="breadcrumb"
            className="print:hidden"
            showHome={false}
            farmRequired={true}
            houseRequired={true}
          />
          <PageHeader
            key="pageHeader"
            title={pageTitle}
            subtitle={pageSubtitle}
            className="py-6 px-4 sm:px-6 lg:px-8 flex flex-row"
          >
            <div className="flex flex-grow justify-end items-center print:hidden flex-col tablet:flex-row">
              <div className="">
                {generateNonConformantTableData.reportData.length > 0 && (
                  <>
                    <Button
                      // onClick={handlePrint}
                      theme="primary"
                      options={[
                        {
                          id: "print-audit-table",
                          text: "Print Audit",
                          onClick: () =>
                            handlePrint(ReportPrintMode.AuditOnlyTable),
                        },
                        {
                          id: "print-nonconformances-table",
                          text: "Print Non-Conformances",
                          onClick: () =>
                            handlePrint(
                              ReportPrintMode.NonConformancesOnlyTable
                            ),
                        },
                        {
                          id: "print-audit-table-w-images",
                          text: "Print Audit (w/ images)",
                          onClick: () =>
                            handlePrint(
                              ReportPrintMode.AuditOnlyTableWithLargeImages
                            ),
                        },
                        {
                          id: "print-nonconformances-table-w-images",
                          text: "Print Non-Conformances (w/ images)",
                          onClick: () =>
                            handlePrint(
                              ReportPrintMode.NonConformancesOnlyTableWithLargeImages
                            ),
                        },
                      ]}
                    >
                      Print
                    </Button>

                    {isPrinting && (
                      <PDFDownloadLink
                        document={
                          <ReportPrintPDF
                            farm={farm}
                            auditForm={auditForm}
                            completedDate={completedDate}
                            auditor={auditData?.CreatedBy ?? user?.FullName}
                            nonconformanceCounts={
                              generateNonConformantTableData.questionStatusLookup
                            }
                            reportData={
                              generateNonConformantTableData.reportData
                            }
                            printImages={
                              generateNonConformantTableData.printImages
                            }
                            printMode={printMode}
                          />
                        }
                        fileName="reportprintpdf.pdf"
                        style={{ display: "none" }}
                      >
                        {({ blob, url, loading, error }) => {

                          return (loading ? (
                            <Modal
                              title={<div className="mb-4">Preparing your document</div>}
                              open={true}
                              showCancel={false}
                            >
                              <p className="mb-2">Once your document is ready it will open in a new tab.</p>
                              <p className="mb-2 text-sm italic">
                                <span className="font-medium">Please note:</span> if your document contains images, it
                                may take longer to generate.
                              </p>
                            </Modal>
                          ) : (
                            <OpenNewWindow
                              url={url}
                              loading={loading}
                              callback={() => setIsPrinting(false)}
                            />
                          )
                          )
                        }}
                      </PDFDownloadLink>
                    )}
                  </>
                )}
              </div>
            </div>
          </PageHeader>
        </div>
        <ReportDetail
          farm={farm}
          form={auditForm}
          ncnForm={nonconformanceForm}
          auditor={auditData?.CreatedBy ?? user?.FullName}
          completedDate={completedDate}
          placement={placement}
          data={generateNonConformantTableData.reportData}
          pwaId={getPWAIDfromFormValuesRecord(auditData)}
          keys={keys}
          questionStatusLookup={
            generateNonConformantTableData.questionStatusLookup
          }
          auditStatus={auditData?.AuditStatus}
        />
      </div>
    </>
  );
}

const ReportDetail = (
  {
    farm,
    form,
    ncnForm,
    auditor,
    completedDate,
    data,
    pwaId,
    questionStatusLookup,
  },
  ref
) => {
  const location = useLocation();
  const { id } = useParams();

  const { setShowModal } = useContext(ModalContext);

  const onClickImage = (url) => {
    setShowModal(true, <img src={url} alt={url} />);
  };

  const summaryData = data.filter((row) => row.section === "summary");

  return (
    <main className="flex flex-col flex-grow print:m-0" id="page">
      <div className="flex flex-col flex-grow w-full mx-auto py-6 space-y-6 tablet:px-6 desktop:px-8 print:table-row print:py-0 print:px-0 print:flex-shrink">
        <div className="block space-y-4 tablet:flex tablet:space-x-4 tablet:space-y-0 print:space-y-2">
          <Widget
            title="Details"
            className="min-h-full flex-grow print:w-auto print:min-h-0 print:flex-shrink"
          >
            {farm?.FarmName !== undefined &&
            form?.FormName !== undefined &&
            completedDate !== undefined ? (
              <DescriptionList
                className="divide-y divide-gray-100"
                items={[
                  {
                    id: "farmcode",
                    title: "Farm",
                    value: `${farm?.FarmName} (${farm?.FarmCode})`,
                  },
                  { id: "form", title: "Form", value: form?.FormName },
                  {
                    id: "date",
                    title: "Date",
                    value: dateToString(completedDate, {
                      includeTime: false,
                    }),
                  },
                  {
                    id: "auditor",
                    title: "Auditor",
                    value: auditor,
                  },
                  // {
                  //   id: "flockdate",
                  //   title: "Flock Date",
                  //   value: dateToString(placement?._CropDate?.normalised, {
                  //     includeTime: false,
                  //   }),
                  // },
                  // {
                  //   id: "auditStatus",
                  //   title: "Audit Status",
                  //   value: auditStatusToText(auditStatus),
                  // },
                ]}
              />
            ) : (
              <DescriptionListSkeleton />
            )}
          </Widget>
          <Widget title="Summary" className="w-auto tablet:w-1/3">
            {questionStatusLookup?.length > 0 ? (
              <DescriptionList
                className="divide-y divide-gray-100"
                items={questionStatusLookup.map((status) => ({
                  id: status.id,
                  title: status.text,
                  value: (
                    <BadgeSeverity severity={status.severity}>
                      {status.value}
                    </BadgeSeverity>
                  ),
                }))}
              />
            ) : (
              <DescriptionListSkeleton />
            )}
          </Widget>
        </div>
        {summaryData.length > 0 &&
          (form?.FormTitle !== undefined ? (
            <>
              {/* <div className="page-break-always hidden print:block"></div> */}
              <Widget title={`${form?.FormTitle ?? "Form"} Summary`}>
                <DescriptionList
                  className="divide-y divide-gray-100 page-break"
                  items={summaryData.map((sd) => {
                    const answer = sd.children.find((c) => c.id === "answer");

                    if (answer.type === "status") {
                      return {
                        id: sd.id,
                        title: sd.title,
                        value: !isNullEmptyOrWhitespace(answer) ? (
                          <BadgeSeverity severity={answer.statusColor}>
                            {answer.value}
                          </BadgeSeverity>
                        ) : null,
                      };
                    }

                    return {
                      id: sd.id,
                      title: sd.title,
                      value: !isNullEmptyOrWhitespace(answer)
                        ? answer.value
                        : "-",
                    };
                  })}
                />
              </Widget>
            </>
          ) : (
            <Widget>
              <DescriptionListSkeleton />
            </Widget>
          ))}
        {/* <div className="page-break-always hidden print:block"></div> */}
        {data?.length > 0 ? (
          <div>
            <h3 className="text-lg text-medium text-gray-600 mb-2">
              Questions
            </h3>
            <div className="space-y-4">
              {data.map((row) => (
                <Widget
                  title={
                    location !== undefined &&
                    ncnForm?.FormName !== undefined &&
                    pwaId !== undefined &&
                    form?.FormName !== undefined &&
                    id !== undefined ? (
                      <Link
                        to={redirectToFormView(
                          location,
                          ncnForm?.FormName,
                          pwaId,
                          form?.FormName,
                          id
                        )}
                      >
                        {row.title}
                      </Link>
                    ) : (
                      <div className="animate-pulse text-sm font-medium text-gray-500 title">
                        <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                      </div>
                    )
                  }
                >
                  <DescriptionList
                    className="divide-y divide-gray-100"
                    items={row.children}
                    onClickImage={(url) => onClickImage(url)}
                  />
                </Widget>
              ))}
            </div>
          </div>
        ) : (
          <DescriptionListSkeleton />
        )}
      </div>
    </main>
  );
};

const OpenNewWindow = ({ loading, url, callback }) => {
  useLayoutEffect(() => {
    if (!loading && url !== null) {
      // console.log(`Opening url: ${url}`);
      
      let a = document.createElement("a");
      a.href = url;
      // a.target = "_blank"; // Will not work in Safari
      a.click();
      a.remove();

      if (callback !== undefined) {
        callback();
      }
    }
  }, [loading, url, callback]);

  return null;
};
