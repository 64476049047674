import React from "react";
import PropTypes from "prop-types";
import BreadcrumbItem from "./BreadcrumbItem";
import { HomeIcon } from "assets/icons";
import classNames from "classnames";

const Breadcrumb = ({
  children,
  className,
  onClickHome,
  showHome = true,
  parentRef,
}) => {
  return (
    <nav
      ref={parentRef}
      className={classNames(className, "bg-gray-50 border-b border-t border-gray-200 flex w-full overflow-x-auto no-scrollbar relative")}
      aria-label="Breadcrumb"
    >
      <ol className="max-w-screen-xl mx-auto flex flex-1 sm:px-6 lg:px-8  transition delay-150 duration-300 ease-in-out translate-x-full">
        {showHome && (
          <BreadcrumbItem
            icon={
              <span className="text-gray-500">
                <HomeIcon className="flex-shrink-0 h-5 w-5" />
                <span className="sr-only">Home</span>
              </span>
            }
            onClick={onClickHome}
          />
        )}
        {children}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
};

export { Breadcrumb, BreadcrumbItem };
