import React from "react";
import ListSkeleton from "./ListSkeleton";

export default function List({
  theme,
  size,
  children,
  className,
  loaded = true,
  ...other
}) {
  const elements = React.Children.toArray(children);
  const newElements = [];
  const classesArr = [];

  if (className) {
    classesArr.push(className);
  }

  // Size
  if (size === "small") {
    classesArr.push("text-sm");
  }

  if (elements.length > 0) {
    elements.forEach((element, index) => {
      const elementClassesArr = [];

      // Theme
      if (theme === "striped") {
        if (index % 2 === 1) {
          elementClassesArr.push("bg-gray-50");
        }
      }

      newElements.push(
        React.cloneElement(element, {
          className: elementClassesArr.join(" "),
          size,
          theme,
        })
      );
    });
  }

  if (!loaded) {
    return <ListSkeleton />;
  }

  return (
    <ul
      className={`divide-y divide-gray-200 ${classesArr.join(" ")}`}
      {...other}
    >
      {newElements}
    </ul>
  );
}
