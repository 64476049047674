import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { dateAdd, dateToString, startOfFromDate } from "helpers/dateUtilities";
import { ListItem } from "components/core";
import ListItemIcon from "components/Schedule/ListItemIcon";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";

export default function ListItemEvent({
  todayEntries,
  event,
  date,
  dateToday,
  dateHatched,
  farmId,
  houseId,
  loaded = true,
  className,
  ...other
}) {
  let navigate = useNavigate();

  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [classes, setClasses] = useState([className]);
  const [formId, setFormId] = useState(undefined);
  const [entry, setEntry] = useState(undefined);

  //#region side-effects

  /**
   * Set entry
   */
  useEffect(() => {
    if (isNull(todayEntries) || isNullEmptyOrWhitespace(event?.FarmScheduleID) || isNullEmptyOrWhitespace(formId)) return;

    const _newEntry = todayEntries.find(
      (e) =>
        e.FormName.toLowerCase() === formId && e.ID === event.FarmScheduleID
    );
    setEntry(_newEntry ?? null);
  }, [todayEntries, event?.FarmScheduleID, formId]);

  // Set To & From date
  useEffect(() => {
    if (!dateHatched || !event) return;

      const { StartDays, EndDays } = event;
      // Start date
      const newFromDate = dateAdd(dateHatched, StartDays, "days");
      setFromDate(newFromDate);
      // End date
      const newToDate = dateAdd(dateHatched, EndDays, "days");
      setToDate(newToDate);
  }, [dateHatched, event]);

  /**
   * Set form Id
   */
  useEffect(() => {
    if (!event?.Type) return;

    switch (event.Type.toLowerCase()) {
      case "v":
        setFormId("vaccine");
        break;
      case "s":
        setFormId("swab");
        break;
      case "t":
        setFormId("task");
        break;
      default:
        setFormId(null);
        break;
    }
  }, [event?.Type]);

  /**
   * Build classes
   */
  useEffect(() => {
    setClasses([className, !loaded ? "animate-pulse" : ""]);
  }, [loaded, className]);

  //#endregion

  //#region Callbacks

  /**
   * Handle list item click
   */
  const handleClick = (recordId, formId) => {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    searchParams.set("formId", formId);
    navigate({
      pathname: `/forms/edit/${recordId}`,
      search: "?" + searchParams.toString(),
    });
  };

  //#endregion

  if (!loaded) {
    return (
      <ListItem className="animate-pulse" aria-label="daily">
        <div className="h-10 w-10 mr-2 rounded-full bg-gray-300"></div>
        <div className="flex-grow">
          <div className="mb-0.5 h-4 w-1/4 bg-gray-300 rounded"></div>
          <div className="text-xs text-gray-500">
            <div className="flex">
              <div className="h-2 w-1/2 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }

  /**
   * Chris says, this will work!!
   * @author Chris (Kermit)
   * @returns
   */
  const shouldShow = () => {
    const completedDate = event?._CompletedDate;
    // Completed event
    if (completedDate && completedDate.normalised.getTime() === date?.getTime())
      return true;

    // Event list date is the past && toDate is valid in future, don't render
    if (
      !completedDate &&
      date?.getTime() < startOfFromDate(dateToday, "day").getTime() &&
      toDate?.getTime() > date?.getTime()
    )
      return false;

    // Event list date is between from & to date, render
    if (
      !completedDate &&
      (date?.getTime() >= fromDate?.getTime() ||
        date?.getTime() <= toDate?.getTime())
    )
      return true;

    return false;
  };

  return shouldShow() ? (
    <ListItem
      aria-label="event"
      className={classes.join(" ")}
      onClick={() => handleClick(event?.FarmScheduleID, formId)}
      {...other}
    >
      <ListItemIcon
        dataStatus={entry?.Status}
        date={date}
        dateToday={dateToday}
        loaded={entry !== undefined}
      />
      <div className="flex-grow">
        <div className="font-medium">{event.Description}</div>
        <div className="text-sm text-gray-900" data-cy="date-range">
          {fromDate && `${dateToString(fromDate, { includeWeekday: true })} -`}{" "}
          {toDate && `${dateToString(toDate, { includeWeekday: true })}`}
        </div>
        <div className="flex text-sm">
          <ListItemStatus
            dataStatus={entry?.Status}
            date={date}
            dateToday={dateToday}
            loaded={entry !== undefined}
          />
        </div>
        {entry && entry._LastModified && (
          <div className="text-xs text-gray-400">
            Updated:{" "}
            {dateToString(entry._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        )}
      </div>
    </ListItem>
  ) : null;
}
