import Dexie, { Table } from "dexie";
import { FormData } from "./FormData";
import { Log } from "./Log";
import { Request } from "./Request";
import { Standard } from "./Standard";

class AppDataDB extends Dexie {
  formdata!: Table<FormData>;
  standards!: Table<Standard>;
  logs!: Table<Log>;

  constructor() {
    super("app-data");

    this.version(0.1).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.version(0.2).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,FormType,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.version(0.3).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,MenuOption,FormName,HouseNumber,DateApplies,LastModified",
    });

    this.version(0.4).stores({
      standards: "[farmGroup+birdType+birdSex],expires",
      formdata:
        "++ID,FarmCode,MenuOption,FormName,HouseNumber,DateApplies,LastModified",
      logs: "++id,timestamp, level",
    });

    this.open().then(() => {
      // Open database then...
      this.deleteOldLogRecords();
    });
  }

  private async deleteOldLogRecords() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    // const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    await this.logs.where("timestamp").below(oneWeekAgo).delete();
  }
}

class WorkboxBackgroundSyncDB extends Dexie {
  requests!: Table<Request>;

  constructor() {
    super("workbox-background-sync");

    this.open().catch((err) => {
      //console.warn(err.stack || err);
    });
  }
}

export const db = new AppDataDB();
export const workboxDB = new WorkboxBackgroundSyncDB();
