import React, { useState, useEffect } from "react";
import { InitialsAvatar } from "../Avatars/InitialsAvatar";
import { ChevronRightIcon } from "../../../assets/icons";
import { generateInitialsFromText } from "helpers/stringUtilities";

export default function ListItem({
  children,
  avatarText,
  size,
  theme,
  isSelected,
  showClickIcon = true,
  clickIcon = <ChevronRightIcon key="clickIcon" className="w-4 h-6" />,
  onClick,
  className = "",
  innerRef,
  ...other
}) {
  const [initials, setInitials] = useState("");
  const classesArr = ["hover:bg-gray-100", className];

  useEffect(() => {
    if (avatarText && avatarText !== initials) {
      setInitials(
        avatarText.length > 3
          ? generateInitialsFromText(avatarText)
          : avatarText
      );
    }
  }, [avatarText, initials]);

  // Size
  if (size === "small") {
    classesArr.push("px-2 py-2 sm:px-4");
  } else {
    classesArr.push("px-4 py-4 sm:px-6");
  }

  // Active item
  if (isSelected) {
    classesArr.push("bg-primary text-white");
  }

  // onClick
  if (onClick) {
    classesArr.push("cursor-pointer");
  }

  return (
    <li
      data-cy="list-item"
      className={classesArr.join(" ")}
      onClick={onClick}
      ref={innerRef}
      {...other}
    >
      <div className="flex items-center">
        <div className="min-w-0 flex-1 flex items-center">
          <InitialsAvatar key="avatar" initials={initials} className="mr-3" />
          {children}
        </div>
        {!!onClick && showClickIcon && (clickIcon)}
      </div>
    </li>
  );
}
