import React from "react";
import FormButtons from "components/forms/FormButtons";
import useSticky from "hooks/useSticky";

export default function Form({
  onFormSubmit,
  onClickCancel,
  children,
  loaded,
  dataStatus,
  showDraft,
  requiredFieldCount,
  requiredFieldCompletedCount,
  button,
  ...other
}) {
  const { targetRef, isSticky } = useSticky({ inverted: true });

  //#region Callbacks

  //#endregion

  //#region Event handlers

  /**
   * Handle form submit
   */
  const handleFormSubmit = (ev, { ...args } = {}) => {
    if (onFormSubmit) {
      onFormSubmit(ev, { ...args });
      return;
    }
  };

  /**
   * Handle click cancel button
   */
  const handleClickCancel = (ev, { ...args } = {}) => {
    if (onClickCancel) {
      onClickCancel(ev, { ...args });
      return;
    }
  };

  //#endregion

  return (
    <form {...other} onSubmit={handleFormSubmit}>
      <div className="flex flex-col flex-grow">{children}</div>
      {loaded && (
        <FormButtons          
          _ref={targetRef}
          key="formButtons"
          size={isSticky ? "small" : undefined}
          className={
            isSticky
              ? "fixed bottom-0 left-0 right-0 bg-gray-100 z-10 border-t border-gray-200 max-w-7xl mx-auto py-3 tablet:px-6 desktop:px-8"
              : ""
          }
          loaded={loaded}
          handleClickCancel={handleClickCancel}
          dataStatus={dataStatus}
          showDraft={showDraft}
          requiredFieldCount={requiredFieldCount}
          requiredFieldCompletedCount={requiredFieldCompletedCount}
          button={button}
        />
      )}
    </form>
  );
}
