import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ChevronUpIcon, ChevronDownIcon, CogIcon, XIcon } from "assets/icons";
import { Pagination, FlyoutMenu, Modal } from "components/core";
import useDebounceEffect from "hooks/useDebounceEffect";
import { isNull, isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import classNames from "classnames";
import { dateToString } from "helpers/dateUtilities";
import { BadgeSeverity } from "components/forms/BadgeSeverity";

const recordsPerPageOptions = [
  {
    value: 5,
    text: "5",
  },
  {
    value: 10,
    text: "10",
  },
  {
    value: 25,
    text: "25",
  },
  {
    value: 50,
    text: "50",
  },
  {
    value: 100,
    text: "100",
  },
];

/**
 * TableChart
 * @param {Object} props
 * @param {String} props.id
 * @param {Array} props.keys
 * @param {String} props.keys.id
 * @param {String} props.keys.title
 * @param {Array} props.data
 * @param {Object} props.data
 */
export const TableChart = (props) => {
  const _sortable = props.settings.sortable ?? true;
  const _showConfig = props.settings.showConfig ?? true;

  const [filteredData, setFilteredData] = useState(props.data);
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState(props.sortBy ?? {});
  const [recordsPerPage, setRecordsPerPage] = useState(
    props.settings.recordsPerPage ?? props.settings.showPagination === false
      ? undefined
      : 5
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showPagination, setShowPagination] = useState(
    props.settings.showPagination ?? true
  );

  const newScrollbarRef = useRef(undefined);
  const tableRef = useRef(undefined);
  const nativeScrollbarRef = useRef(undefined);
  //#region Callbacks

  //#endregion

  //#region Side-effects

  useEffect(() => {
    function setScrollbarTopWidth() {
      if (newScrollbarRef.current && tableRef.current) {
        //set scrollbar width to same as table width
        newScrollbarRef.current.style.width =
          tableRef.current.clientWidth + "px";
      }
    }

    function setNewScrollbarPosition() {
      if (newScrollbarRef.current && nativeScrollbarRef.current) {
        //set scrollbar position to same as table position
        nativeScrollbarRef.current.scrollLeft =
          newScrollbarRef.current.parentNode.scrollLeft;
      }
    }

    function setNativeScrollbarPosition() {
      if (newScrollbarRef.current && nativeScrollbarRef.current) {
        //set scrollbar position to same as table position
        newScrollbarRef.current.parentNode.scrollLeft =
          nativeScrollbarRef.current.scrollLeft;
      }
    }

    setScrollbarTopWidth();
    document.addEventListener("resize", () => {
      setScrollbarTopWidth();
    });

    newScrollbarRef.current.parentNode.addEventListener("scroll", () => {
      setNewScrollbarPosition();
    });

    nativeScrollbarRef.current.addEventListener("scroll", () => {
      setNativeScrollbarPosition();
    });
  }, []);

  useDeepCompareEffect(() => {
    if (!props.data?.length) return;

    setFilteredData(props.data);
  }, [props.data, recordsPerPage]);

  useEffect(() => {
    if (props.settings?.showPagination !== undefined) {
      setShowPagination(props.settings.showPagination);
    }
  }, [props.settings?.showPagination]);

  useDebounceEffect(
    () => {
      if (isNull(props.data)) return;

      if (isNullEmptyOrWhitespace(filter)) {
        setFilteredData(props.data);
        return;
      }

      const newFilteredData = props.data.filter((record) =>
        Object.values(record).some((value) =>
          value.toLowerCase().includes(filter.toLowerCase())
        )
      );
      setFilteredData(newFilteredData);
    },
    [props.data, filter],
    500
  );

  /**
   * Set total pages
   */
  useDeepCompareEffect(() => {
    if (!showPagination) return;

    let newTotalPages = Math.ceil(filteredData.length / recordsPerPage);
    newTotalPages = newTotalPages > 0 ? newTotalPages : 1; // Ensure it is never zero
    setTotalPages(newTotalPages);
    setCurrentPage(1);
  }, [filteredData, recordsPerPage]);

  /**
   * Listen for records per page change
   */
  useEffect(() => {
    if (!showPagination) return;
    if (!props.settings.recordsPerPage) return;

    setRecordsPerPage((prevState) =>
      prevState !== props.settings?.recordsPerPage
        ? props.settings.recordsPerPage
        : prevState
    );
  }, [props.settings?.recordsPerPage, showPagination]);

  //#endregion

  function handleSortByKey(key) {
    let _filteredData = [...filteredData];
    const direction = sortBy.direction === "asc" ? "desc" : "asc";
    if (direction === "desc") {
      _filteredData = _filteredData.sort((a, b) =>
        b[key]?.toString().localeCompare(a[key]?.toString())
      );
    } else {
      _filteredData = _filteredData.sort((a, b) =>
        a[key]?.toString().localeCompare(b[key]?.toString())
      );
    }
    setFilteredData(_filteredData);
    setSortBy({ key, direction });

    if (props.onSortBy) {
      props.onSortBy(key, direction);
    }
  }

  function onChangePage(page) {
    setCurrentPage(page);
  }

  function onChangeRecordsPerPage(value) {
    setRecordsPerPage(parseInt(value));
    setCurrentPage(1);
  }

  function onSelectedImage(imgSrc) {
    setSelectedImage(imgSrc);
    setShowModal(true);
  }

  function getPageFirstRecordIndex() {
    if (!showPagination) return 0;
    return getPageEndRecordIndex() - recordsPerPage;
  }

  function getPageEndRecordIndex() {
    if (!showPagination) return filteredData.length;
    return currentPage * recordsPerPage;
  }

  function getPageRecordIndices() {
    return [getPageFirstRecordIndex(), getPageEndRecordIndex()];
  }

  return (
    <div className="flex flex-col relative">
      {/* Header */}
      {_showConfig && (
        <div className="flex px-4 py-2 laptop:py-4 flex-1 space-x-4 items-center justify-end print:hidden">
          <div className="hidden laptop:flex space-x-2">
            {showPagination && (
              <SelectRecordsPerPage
                recordsPerPage={recordsPerPage}
                onChangeRecordsPerPage={onChangeRecordsPerPage}
              />
            )}
            <InputFilterBy filter={filter} setFilter={setFilter} />
          </div>

          <FlyoutMenu
            className="block laptop:hidden justify-end"
            buttonContent={() => <CogIcon className="h-4 w-4" />}
          >
            <div className="space-y-4">
              {showPagination && (
                <SelectRecordsPerPage
                  recordsPerPage={recordsPerPage}
                  onChangeRecordsPerPage={onChangeRecordsPerPage}
                />
              )}
              <InputFilterBy filter={filter} setFilter={setFilter} />
            </div>
          </FlyoutMenu>
        </div>
      )}
      {/* Body */}
      <div className="w-full overflow-x-auto hidden tablet:block">
        <div ref={newScrollbarRef} className="h-px"></div>
      </div>
      <div
        ref={nativeScrollbarRef}
        className="overflow-x-auto print:overflow-visible print:m-0 print:p-0 transform"
      >
        <div className="align-middle inline-block min-w-full print:m-0 print:p-0 print:block transform">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg print:overflow-visible print:m-0 print:p-0">
            <table
              ref={tableRef}
              className="min-w-full divide-y divide-gray-200 print:max-w-full"
            >
              <thead className="bg-gray-50">
                <tr>
                  {props.keys.map((key) => (
                    <th
                      key={key.title}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-medium text-gray-700 print:px-2 min-w-[100px]"
                      onClick={() => handleSortByKey(key.id)}
                    >
                      <div
                        className={classNames(
                          "inline-flex items-center",
                          _sortable && "cursor-pointer hover:text-primary"
                        )}
                      >
                        <div>{key.title}</div>
                        {_sortable &&
                          key.id === sortBy.key &&
                          sortBy.direction &&
                          (sortBy.direction === "asc" ? (
                            <div className="ml-2 flex-none text-gray-400 font-medium">
                              <ChevronUpIcon className="h-5 w-5" />
                            </div>
                          ) : (
                            <div className="ml-2 flex-none text-gray-400">
                              <ChevronDownIcon className="h-5 w-5" />
                            </div>
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-sm text-gray-900">
                {filteredData
                  .slice(...getPageRecordIndices())
                  .map((record, index) => (
                    <tr
                      key={record.key ?? record.id}
                      className={classNames(
                        index % 2 === 0 ? "bg-white" : "bg-gray-50",
                        record.onClick
                          ? "cursor-pointer hover:bg-gray-100"
                          : "",
                        "page-break"
                      )}
                      onClick={record.onClick}
                    >
                      {props.keys.map((key, index) => (
                        <TableRowCell
                          key={`${key.id}-${record.key ?? record.id}`}
                          index={index}
                          id={key.id}
                          record={record}
                          contentType={key.type}
                          onSelectedImage={onSelectedImage}
                        />
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
            <Modal
              open={showModal}
              onOpen={(_open) => setShowModal(_open)}
              showCancel={false}
            >
              <img src={selectedImage} alt={selectedImage} />
            </Modal>
          </div>
        </div>
      </div>
      {/* Footer */}
      {showPagination && totalPages > 1 && (
        <div className="p-4 flex-1 flex space-x-4 items-center print:hidden">
          <Pagination
            recordCount={filteredData.length}
            recordsPerPage={recordsPerPage}
            totalPages={totalPages}
            currentPage={currentPage}
            onChangePage={onChangePage}
          />
        </div>
      )}
    </div>
  );
};

function InputFilterBy({ filter, setFilter }) {
  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label
        htmlFor="table-filter"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Filter by...
      </label>
      <div className="relative">
        <input
          type="text"
          name="filterBy"
          id="filterBy"
          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm mt-1"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          autoComplete="off"
        />
        {!!filter && (
          <div
            className="absolute inset-y-0 right-0 flex items-center cursor-pointer"
            onClick={() => setFilter("")}
          >
            <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
}

function SelectRecordsPerPage({ recordsPerPage, onChangeRecordsPerPage }) {
  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
      <label
        htmlFor="table-filter"
        className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Records per page...
      </label>
      <select
        id="table-records-per-page"
        className="block w-full border-0 py-0 px-2 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm mt-1"
        value={recordsPerPage}
        onChange={(ev) => onChangeRecordsPerPage(ev.target.value)}
        style={{ minWidth: "180px" }}
      >
        {recordsPerPageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}

function TableRowCell({ id, index, record, contentType, onSelectedImage }) {
  if (record[id] instanceof Function) return null;

  if (
    !isNull(id) &&
    !isNull(record) &&
    id === "images" &&
    record[id]?.length > 0
  ) {
    return (
      <td
        key={id}
        className={classNames(
          index === 0 ? "pl-4 pr-2" : "px-2",
          "whitespace-nowrap text-sm text-gray-900",
          record[id]?.length > 1 ? "py-2" : "py-4"
        )}
      >
        <div className="flex-shrink-0 sm:mt-0 sm:ml-5">
          <div className="flex overflow-hidden -space-x-1">
            {record[id].map((img) => (
              <img
                key={img}
                className="inline-block h-8 w-8 rounded-full border border-gray-300 ring-2 ring-white cursor-pointer hover:border-primary relative hover:z-10"
                src={img}
                alt={img}
                onClick={() => onSelectedImage(img)}
              />
            ))}
          </div>
        </div>
      </td>
    );
  }

  function formatValue(value) {
    if (isNullEmptyOrWhitespace(value)) {
      return "-";
    }

    if (contentType === "number") {
      return <div className="text-right">{value}</div>;
    }

    if (contentType === "date") {
      return <div className="whitespace-nowrap">{dateToString(value)}</div>;
    }

    if (contentType === "status") {
      if (value?.Text !== undefined) {
        return (
          <BadgeSeverity severity={value?.Severity}>{value.Text}</BadgeSeverity>
        );
      }

      return value;
    }

    return (
      <div
        className={classNames(
          value.length > 100 ? "whitespace-normal" : "whitespace-nowrap"
        )}
      >
        {value}
      </div>
    );
  }

  return (
    <td
      key={id}
      className={classNames(
        index === 0 ? "pl-4 pr-2" : "px-3",
        "py-4 print:whitespace-normal print:pl-2 align-top"
      )}
    >
      {formatValue(record[id])}
    </td>
  );
}

TableChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  sortBy: PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
};
