import React, {
  useState,
  createContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { db } from "../db";
import {
  startOfLastXDays,
  dateDiffInDays,
  localDateToSQLDate,
  sqlDateObjectFromServerTZ,
  localDate,
  localDateFromSQL,
  endOfFromDate,
  dateSubtract,
} from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace, parseJSON } from "helpers/stringUtilities";
import { setCookie, deleteCookie } from "helpers/storageUtilities";
import cookie from "cookie";
import { getPWAIDfromFormValuesRecord } from "helpers/formsUtilities";

export const AppDataContext = createContext(undefined);

export function AppDataProvider({ children }) {
  const [user, setUser] = useState();
  const [isSignedIn, setIsSignedIn] = useState(() => {
    const cookies = cookie.parse(document.cookie);
    return cookies.signedIn === "true" || cookies.signedIn === true
      ? true
      : false;
  });
  const [farms, setFarms] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [offline, setOffline] = useState(false);
  const [menus, setMenus] = useState([]);
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [config, setConfig] = useState({});

  const abortControllerRef = useRef(undefined);

  //#region callbacks

  /**
   * Fetch standards items for each farm house, save to browser indexedDb with expiry
   * @param {string}  farmId  - The farm code
   * @param {number}  houseId  - The house number
   * @returns {null|Promise}
   */
  const updateStandards = useCallback(
    async (farmGroup, birdType, birdSex, signal, stdTypes = "") => {
      if (!farms?.length) return;
      farmGroup = farmGroup?.toLowerCase();
      birdType = birdType?.toLowerCase();
      birdSex = birdSex?.toLowerCase();
      stdTypes = stdTypes?.toLowerCase();

      // TODO temp fix, always refresh standards
      // // Fetch from cache, if exists and NOT expired
      // const existingStandard = await db.standards.get([
      //   farmGroup,
      //   birdType,
      //   birdSex,
      // ]);
      // if (existingStandard && existingStandard.expires > new Date().getTime()) {
      //   // Don't re-fetch, cache still valid
      //   return;
      // }

      // Fetch from web service
      return fetch(
        `/api/standards-get?farmGroup=${farmGroup}&birdType=${birdType}&birdSex=${birdSex}&stdTypes=${stdTypes}`,
        {
          signal,
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (signal.aborted) return;

          const expires = localDate().getTime() + 86400 * 7 * 1000; // Expires in 1 week
          db.standards.put({
            farmGroup,
            birdType,
            birdSex,
            data,
            expires,
          });
        })
        .catch((error) => {
          if (signal.aborted) return;
          console.error(error.message);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [farms]
  );

  /**
   * Fetch farm schedules.
   * @returns {null|Promise}
   */
  const updateSchedules = useCallback(
    async (signal) => {
      if (!isSignedIn || signal.aborted) return;

      // Fetch from web service
      return fetch("/api/schedules-get", {
        signal,
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (signal.aborted || !data?.length) return;

          // Change all server datetimes to local date object, making comparison easier going forward
          const newSchedules = data.map((record) => {
            return {
              ...record,
              _CompletedDate: sqlDateObjectFromServerTZ(record.CompletedDate),
            };
          });

          setSchedules(newSchedules);
        })
        .catch((error) => {
          if (signal.aborted) return;
          console.error(error.message);
        });
    },
    [isSignedIn]
  );

  /**
   * Fetch form templates for each farm group, save to browser indexedDb with expiry
   * @param {string}  farmGroup  - The farm code
   * @returns {null|Promise}
   */
  const fetchFormTemplates = useCallback(
    async (farmGroup, signal) => {
      if (!farms?.length) return;
      farmGroup = farmGroup.toLowerCase();

      try {
        // Fetch from web service
        const response = await fetch(`/api/forms-get?farmGroup=${farmGroup}`, {
          signal,
          method: "GET",
        });
        if (signal.aborted) return;
        if (response.status === 401) {
          // Unauthorised
          onSignOut();
        }

        const result = await response.json();

        // Convert meta string to JSON
        result.forEach((form) => {
          form.FormFields.forEach((field) => {
            // Parse metadata
            const parsed = parseJSON(field.Display);

            // Set metadata as JSON
            field.Display = parsed;
          });
        });

        return result;
      } catch (err) {
        if (signal.aborted) return;

        console.error(err.message);
      }
    },
    [farms]
  );

  /**
   * Fetch all farm house form values between dates.
   * @param {string} farmId - The farm code.
   * @param {number|string} houseId - The house number.
   * @param {string} startDate - The start date in YYYY-mm-dd format
   * @param {string} endDate - The start date in YYYY-mm-dd format
   * @param {AbortSignal} signal - The abort controller signal status.
   * @param {[formIds]} formIds - An array of formIds to fetch values for. Default: all form Ids.
   * @returns {Promise<any> | Promise<[any, any, any]>}
   */
  const fetchAllFormValues = useCallback(
    async (farmId, houseId, formIds, signal) => {
      if (!farms?.length || !formIds?.length) return;

      farmId = farmId?.toLowerCase();

      const _formIds = formIds.map(({ formId, menuOption }) => ({
        formId: formId.toLowerCase(),
        menuOption: menuOption.toLowerCase(),
      }));

      let fetches = [];
      _formIds.forEach(({ formId, menuOption }) => {
        const farm = farms.find((f) => f.FarmCode.toLowerCase() === farmId);
        const house = farm.Houses.find(
          (h) => h.HouseNumber.toString() === houseId.toString()
        );
        // Build array of fetchs
        fetches.push(
          fetchFormValues(formId, menuOption, farm, house, signal, onSignOut)
        );
      });

      return Promise.all(fetches);
    },
    [farms]
  );

  /**
   * Fetch menus
   */
  const fetchFormMenus = async (signal) => {
    return fetch("/api/menus-get", {
      signal,
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        if (signal.aborted || result === undefined) return;

        result.sort((a, b) => a.Position - b.Position);

        // Convert meta string to JSON
        result.forEach((m) => {
          // Parse metadata
          const parsed = parseJSON(m.Metadata);

          // Set metadata as JSON
          m.Metadata = parsed;
        });

        setMenus(result ?? []);

        return result;
      })
      .catch((error) => {
        if (signal.aborted) return;

        console.error("error", error);
      });
  };

  /**
   * Get user signed in status from 'signedIn' cookie.
   * @returns {boolean} True/false user 'signedIn' cookie exists.
   */
  const updateIsSignedInWithCookieStatus = () => {
    const cookies = cookie.parse(document.cookie);
    const _isSignedInCookie =
      cookies.signedIn === "true" || cookies.signedIn === true;
    // Careful to only change the state when we need to here
    setIsSignedIn((prevState) =>
      prevState !== _isSignedInCookie ? _isSignedInCookie : prevState
    );
  };

  /**
   * Handle network status change (online/offline)
   * @param {Event} ev
   */
  const handleNetworkChange = (ev) => {
    const online = ev.type === "online" ? true : false;
    setOffline(!online);

    if (online) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: "REPLAY_REQUESTS",
        });
      }
    }
  };

  //#endregion

  //#region side-effects

  /**
   * Mount/Unmount
   */
  useEffect(() => {
    abortControllerRef.current = new AbortController();

    // Set initial signed in status
    updateIsSignedInWithCookieStatus();

    /**
     * Listen for 'signedIn' cookie expiry
     */
    const loggedInInterval = setInterval(
      updateIsSignedInWithCookieStatus,
      2000
    );

    return () => {
      abortControllerRef.current.abort();
      clearInterval(loggedInInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch config data
   */
  useEffect(() => {
    if (!isSignedIn) return;
    const { signal } = abortControllerRef.current;

    const fetchConfig = () => {
      fetch("/api/config-get", {
        method: "GET",
        signal,
      })
        .then((response) => response.json())
        .then((result) => {
          if (signal.aborted) return;

          setConfig(result ?? {});
        })
        .catch((error) => {
          if (signal.aborted) return;

          console.error("error", error);
        });
    };

    fetchConfig();
  }, [isSignedIn]);

  /**
   * Clear user data on signout
   */
  useEffect(() => {
    if (isSignedIn) return;

    if (user) onSignOut();
  }, [isSignedIn, user]);

  /**
   * Fetch user data
   */
  useEffect(() => {
    if (!isSignedIn || user) return;

    /**
     * Fetch user data and populate state.
     * @returns {null|Promise}
     */
    const getUserData = async () => {
      const { signal } = abortControllerRef.current;
      try {
        const res = await fetch(`/api/user-get`, {
          signal,
          method: "GET",
        });
        if (signal.aborted) return;
        if (res.status === 401) {
          // Unauthorised
          onSignOut();
        }

        const json = await res.json();
        if (!signal.aborted) {
          setUser(json.user);
        }
      } catch (error) {
        if (signal.aborted) return;

        console.error(error.message);
      }
    };

    getUserData();
  }, [isSignedIn, user]);

  /**
   * Fetch menus data
   */
  useEffect(() => {
    if (!isSignedIn) return;
    const { signal } = abortControllerRef.current;

    fetchFormMenus(signal);
  }, [isSignedIn]);

  /**
   * Fetch farms data
   */
  useEffect(() => {
    if (!isSignedIn) return;

    /**
     * Fetch user data and populate state.
     * @returns {null|Promise}
     */
    function getFarmsData() {
      const { signal } = abortControllerRef.current;

      return fetch(`/api/farms-get`, {
        signal,
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (signal.aborted) return;

          // Change all server datetimes to local date object, making comparison easier going forward
          const newFarms = data.map((record) => {
            return {
              ...record,
              Houses: record?.Houses.map((house) => {
                return {
                  ...house,
                  Pens: house?.Pens.map((pen) => {
                    return {
                      ...pen,
                      Placement: {
                        ...pen?.Placement,
                        _HatchDate: sqlDateObjectFromServerTZ(
                          pen?.Placement?.HatchDate
                        ),
                        _CropDate: sqlDateObjectFromServerTZ(
                          pen?.Placement?.CropDate
                        ),
                        _DatePlaced: sqlDateObjectFromServerTZ(
                          pen?.Placement?.DatePlaced
                        ),
                        _DepopDate: sqlDateObjectFromServerTZ(
                          pen?.Placement?.DepopDate
                        ),
                      },
                    };
                  }),
                };
              }),
            };
          });

          newFarms.sort((a, b) => a.FarmCode.localeCompare(b.FarmCode));

          setFarms(newFarms);
        })
        .catch((error) => {
          if (signal.aborted) return;
          console.error(error.message);
        });
    }

    getFarmsData();
  }, [isSignedIn]);

  /**
   * Fetch standards
   */
  useEffect(() => {
    if (!farms?.length || !isSignedIn || !updateStandards) return;

    // Build array of standards request data
    const standardsRequestData = [];
    farms.forEach((farm) => {
      farm.Houses.forEach((house) => {
        house.Pens.forEach((pen) => {
          if (pen.Placement?.BirdType) {
            standardsRequestData.push({
              FarmGroup: farm.FarmGroup,
              BirdType: pen.Placement.BirdType,
              BirdSex: pen.Placement.BirdSex,
            });
          }
        });
      });
    });

    // Remove duplicates
    const uniqueStandards = standardsRequestData.reduce((acc, standard) => {
      const hasStandard = !!acc.find(
        (uniqueStandard) =>
          uniqueStandard.FarmGroup === standard.FarmGroup &&
          uniqueStandard.BirdType === standard.BirdType &&
          uniqueStandard.BirdSex === standard.BirdSex
      );

      if (!hasStandard) {
        return [...acc, standard];
      }

      return acc;
    }, []);

    // Fetch standards data
    uniqueStandards.forEach((standard) =>
      updateStandards(
        standard.FarmGroup,
        standard.BirdType,
        standard.BirdSex,
        abortControllerRef.current.signal
      )
    );
  }, [farms, isSignedIn, updateStandards]);

  /**
   * Fetch form values
   */
  useEffect(() => {
    if (!farms?.length || !isSignedIn || !fetchAllFormValues || !menus?.length)
      return;

    const { signal } = abortControllerRef.current;

    function getDateRange(datePlaced) {
      var numDays = dateDiffInDays(datePlaced, localDate());
      if (numDays > 20) {
        numDays = 20;
      }

      return startOfLastXDays(numDays + 1); // +1 to allow fetching previous days data
    }

    function getAllFormIds() {
      // Add production form names that doesn't exist in 'menus'
      const formIds = [
        { formId: "production", menuOption: "production" },
        { formId: "weeklyproduction", menuOption: "production" },
        { formId: "swab", menuOption: "production" },
        { formId: "vaccine", menuOption: "production" },
        { formId: "task", menuOption: "production" },
      ];

      for (const menu of menus.filter(
        (m) => m.MenuOption.toLowerCase() !== "production"
      )) {
        for (const form of menu.Forms) {
          if (
            !formIds.some(
              (fi) =>
                fi.formId === form.FormName.toLowerCase() &&
                fi.menuOption === menu.MenuOption.toLowerCase()
            )
          ) {
            formIds.push({
              formId: form.FormName.toLowerCase(),
              menuOption: menu.MenuOption.toLowerCase(),
            });
          }
        }
      }

      return formIds;
    }
    const formIds = getAllFormIds();

    // Build array of farms request data
    const data = [];
    const requestLimit = 1;
    farmloop: for (let i = 0; i < farms.length; i++) {
      for (let j = 0; j < farms[i].Houses.length; j++) {
        if (data.length >= requestLimit) break farmloop;

        const farm = farms[i];
        const house = farm.Houses[j];
        const pen1 = house.Pens.find((p) => p.PenNumber.toString() === "1");
        if (!house.Pens?.length || !pen1?.Placement?._DatePlaced?.normalised)
          continue;

        // Get start and end dates
        const dates = getDateRange(pen1.Placement._DatePlaced.normalised);
        if (!dates?.length) continue;

        const startDate = localDateToSQLDate(dates[dates.length - 1]); // YYYY-mm-dd format expected
        const endDate = localDateToSQLDate(dates[0]); // YYYY-mm-dd format expected

        data.push({
          farmCode: farm.FarmCode,
          houseNumber: house.HouseNumber,
          startDate,
          endDate,
        });
      }
    }

    // Fetch form value data
    data.forEach((item) => {
      fetchAllFormValues(item.farmCode, item.houseNumber, formIds, signal);
    });
  }, [farms, isSignedIn, fetchAllFormValues, menus]);

  /**
   * Fetch schedules
   */
  useEffect(() => {
    if (!updateSchedules) return;

    updateSchedules(abortControllerRef.current.signal);
  }, [updateSchedules]);

  /**
   * Fetch form templates
   */
  useEffect(() => {
    if (!farms?.length || !fetchFormTemplates) return;

    const userFarmGroups = new Set();
    farms.forEach((f) => {
      userFarmGroups.add(f.FarmGroup);
    });

    userFarmGroups.forEach((fg) =>
      fetchFormTemplates(fg, abortControllerRef.current.signal)
    );
  }, [farms, fetchFormTemplates]);

  /**
   * Set online/offline status
   */
  useEffect(() => {
    const onlineListener = window.addEventListener(
      "online",
      handleNetworkChange
    );
    const offlineListener = window.addEventListener(
      "offline",
      handleNetworkChange
    );
    // set initial state
    setOffline(!window.navigator.onLine);
    return () => {
      window.removeEventListener(onlineListener, handleNetworkChange);
      window.removeEventListener(offlineListener, handleNetworkChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  /**
   * Triggered on sign in
   * @param {object} user - User data object.
   */
  const onSignIn = (user) => {
    setIsSignedIn(true);
    setCookie("signedIn", "true", { expires: 365 }); // 1 year
  };

  /**
   * Triggered on sign out
   */
  const onSignOut = () => {
    clearBrowserCache();
    setIsSignedIn(false);
    deleteCookie("signedIn");
    setUser(null);
    setSchedules([]);
  };

  return (
    <AppDataContext.Provider
      value={{
        config,
        farms,
        setFarms,
        schedules,
        setSchedules,
        user,
        isSignedIn,
        setIsSignedIn,
        onSignIn,
        onSignOut,
        offline,
        db, // indexeddb
        updateSchedules,
        fetchFormValues: fetchAllFormValues,
        fetchFormTemplates,
        menus,
        pageSubtitle,
        setPageSubtitle,
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
}

/**
 * Fetch farm house form values between dates.
 * @param {string} formId - The form ID. e.g. Production
 * @param {string} menuOption - The form Type. e.g. Audit
 * @param {string} farmId - The farm code.
 * @param {number|string} houseId - The house number.
 * @param {string} startDate - The start date in YYYY-mm-dd format
 * @param {string} endDate - The start date in YYYY-mm-dd format
 * @param {AbortSignal} signal - The abort controller signal status.
 * @returns {Promise<any> | Promise<[any, any, any]>}
 */
async function fetchFormValues(
  formId,
  menuOption,
  farm,
  house,
  signal,
  onSignOut
) {
  formId = formId?.toLowerCase();
  menuOption = menuOption?.toLowerCase();
  let startDate = "";
  let endDate = endOfFromDate(localDate(), "day");

  if (
    !["production", "weeklyproduction", "swab", "vaccine", "task"].includes(
      formId
    )
  ) {
    startDate = dateSubtract(endDate, 90, "day");
  } else {
    // Production/schedules/weeklyproduction dates are all based off of DatePlaced
    const placement = house.Pens.find(
      (p) => p.PenNumber.toString() === "1"
    ).Placement;
    let numDays = dateDiffInDays(
      placement?._DatePlaced?.normalised,
      localDate()
    );

    numDays = numDays > 0 ? Math.floor(numDays) : 0;

    if (numDays > 20) {
      numDays = 20;
    }
    startDate = dateSubtract(endDate, numDays + 1, "day");
  }

  // Get all localData records between start and end dates.
  let localData = await db.formdata
    .where("DateApplies")
    .between(startDate.getTime(), endDate.getTime(), true, true)
    .toArray();
  // unable to chain filter indexedDb when using `between`
  // Lets do it in memory
  localData = localData.filter(
    (ld) =>
      ld.FormName === formId &&
      ld.MenuOption === menuOption &&
      ld.FarmCode === farm.FarmCode.toLowerCase() &&
      ld.HouseNumber === house.HouseNumber.toString()
  );

  // Sort to ensure localDate is in chronological descending order
  localData.sort((a, b) => b.LastModified - a.LastModified);

  // prettier-ignore
  // console.log("localData", formId, localData?.[0]?.DateApplies, "startDate", startDate, startDate.getTime(), "endDate", endDate, endDate.getTime(), localData?.[0]?.DateApplies >= startDate.getTime() && localData?.[0]?.DateApplies <= endDate.getTime());

  let newNetworkData = [];

  try {
    const networkResponse = await fetch(
      `/api/formvalues-get?formId=${formId}&menuOption=${menuOption}&farmId=${farm.FarmCode.toLowerCase()}&startDate=${localDateToSQLDate(
        startDate
      )}&endDate=${localDateToSQLDate(endDate)}`,
      {
        signal,
        method: "GET",
      }
    );
    if (signal.aborted) return;
    if (networkResponse.status === 401) {
      // Unauthorised
      onSignOut();
    }
    const networkData = await networkResponse.json();

    // networkResponse contains data for each house...
    // let's filter out houses we aren't interested in.
    const houseFilteredNetworkData = networkData?.filter(
      (fv) => fv.House.toString() === house.HouseNumber.toString()
    );

    // Create new network data array replacing network data record with more up-to-date local data
    newNetworkData = houseFilteredNetworkData.map((networkEntity) => {
      // Add request data back onto response data,
      // useful for identifying form values.
      networkEntity.FormName = formId;
      networkEntity.MenuOption = menuOption;
      const dateApplies = localDateFromSQL(networkEntity.DateApplies).getTime();
      const networkLastModifiedDate = sqlDateObjectFromServerTZ(
        networkEntity.LastModified
      );

      // Build an array of indices of all entries that match networkEntity in localData
      const localEntityIndices = [];
      const networkPWAID = getPWAIDfromFormValuesRecord(networkEntity);
      localData.forEach((ld, index) => {
        const localPWAID = ld.ID;

        // prettier-ignore
        // console.log("localPWAID", localPWAID, "networkPWAID", networkPWAID);

        /**
         * - localPWAID: can be undefined | string.
         * - networkPWAID: can be undefined | string.
         * - ld.Data.ID: can be null | string | number.
         * - networkEntity.ID: can be null | string | number.
         * - localPWAID & networkPWAID value comparison should only take place when they both contain an actual value.
         * - ld.Data.ID & networkEntity.ID values should always be compared. The use of ?.toString() will convert null & undefined to undefined,
         *  this is required for comparison against forms with no ID, such as WeeklyProduction.
         */
        if (
          ((!isNullEmptyOrWhitespace(localPWAID) &&
            !isNullEmptyOrWhitespace(networkPWAID) &&
            localPWAID === networkPWAID) ||
            ld.Data.ID?.toString() === networkEntity.ID?.toString()) &&
          ld.FormName.toLowerCase() === formId &&
          ld.MenuOption.toLowerCase() === menuOption &&
          ld.FarmCode.toLowerCase() === farm.FarmCode.toLowerCase() &&
          ld.HouseNumber.toString() === house.HouseNumber.toString() &&
          ld.DateApplies === dateApplies
        ) {
          localEntityIndices.push(index);
        }
      });

      // prettier-ignore
      // console.log(localEntityIndices, localData?.[localEntityIndices[0]]?.LastModified >
      //   networkLastModifiedDate.localised.getTime(), localData?.[localEntityIndices[0]]?.LastModified >
      //   networkLastModifiedDate.localised.getTime());

      if (localEntityIndices.length) {
        // Find matching local entity in list
        const matchingLocalEntity = localData[localEntityIndices[0]];
        if (
          matchingLocalEntity.LastModified >
          networkLastModifiedDate.localised.getTime()
        ) {
          // Latest matching entity
          return mergeLocalDataWithNetworkData(
            localEntityIndices,
            networkEntity,
            matchingLocalEntity
          );
        }
      }

      // Lastly
      for (var i = localEntityIndices.length - 1; i >= 0; i--) {
        // Delete local entity from local DB
        db.formdata.delete(localData[localEntityIndices[i]].ID);
        // Remove stale matched entites from memory
        localData.splice(localEntityIndices[i], 1);
      }

      return networkEntity;
    });
  } catch (err) {
    if (signal.aborted) return;

    console.error(err.message);
  } finally {
    // Push remaining local entities into networkData that don't exist on network
    newNetworkData = newNetworkData.concat(
      localData
        .filter(
          (ld) =>
            ld.FormName.toLowerCase() === formId &&
            ld.MenuOption.toLowerCase() === menuOption
        )
        .map((ld) => ld.Data)
    );

    // Change all server datetimes to local date object, making comparison easier going forward
    const localisedNetworkData = newNetworkData.map((record) => {
      return {
        ...record,
        _DateApplies: sqlDateObjectFromServerTZ(record.DateApplies),
        _LastModified:
          record.LastModified !== "0001-01-01 00:00:00"
            ? sqlDateObjectFromServerTZ(record.LastModified)
            : null,
      };
    });

    return localisedNetworkData;
  }

  function mergeLocalDataWithNetworkData(
    localEntityIndices,
    networkEntity,
    matchingLocalEntity
  ) {
    // Remove from localData to prevent appending it twice
    localData.splice(localEntityIndices[0], 1);

    // Remove index from indices to ensure it isn't deleted
    localEntityIndices.splice(0, 1);

    // Replace network entity PenValues.Values with local
    return {
      ...networkEntity,
      LastModified: matchingLocalEntity.Data.LastModified,
      Status: matchingLocalEntity.Data.Status,
      PenValues: networkEntity.PenValues.map((networkPen) => {
        const matchingLocalPen = matchingLocalEntity.Data.PenValues.find(
          (pv) => pv.Pen.toString() === networkPen.Pen.toString()
        );

        return {
          ...networkPen,
          Values: matchingLocalPen
            ? matchingLocalPen.Values.map((value) => value)
            : [],
        };
      }),
    };
  }
}

function clearBrowserCache() {
  try {
    const cachesToKeep = ["images", ""];

    caches.keys().then((keyList) => {
      return Promise.all(
        keyList
          .filter(
            (key) =>
              key.startsWith("workbox-") === false &&
              cachesToKeep.indexOf(key) === -1
          )
          .map((key) => {
            return caches.delete(key);
          })
      );
    });
  } catch (err) {
    console.error("An error occurred while clearing browser cache.");
  }
}
