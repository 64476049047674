import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import { Label } from "components/core";
import Addon from "./Addon";
import { ExclamationCircleIcon } from "assets/icons";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";

const Input = ({
  label,
  id,
  hint,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  type = "text",
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  labelSize,
  addonLeft,
  addonLeftInteractable = false,
  addonRight,
  addonRightInteractable = false,
  dependencies = [],
  className = "",
  inputClassName = "",
  defaultValue,
  theme,
  size,
  render = true,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const hasDefaultValueRef = useRef(false); // Prevent retriggering of default value


  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(value?.length > 0);

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md",
    "border",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  if (!isNullEmptyOrWhitespace(inputClassName))
    inputClasses.push(inputClassName);
  const errorIconClasses = [];

  // Addons
  if (addonLeft) {
    inputClasses.push("pl-10");
  }
  if (addonRight) {
    inputClasses.push("pr-10");
  }

  // Size
  if (size === "sm") {
    inputClasses.push("py-2");
  } else {
    inputClasses.push("py-3");
  }

  // Theme
  if (theme === "darkgray") {
    inputClasses.push(
      "bg-gray-900 border-gray-500 text-gray-400 focus:border-gray-400 focus:ring-gray-800"
    );
    errorIconClasses.push("bg-gray-900");
  } else {
    inputClasses.push(
      "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
    );
    errorIconClasses.push("bg-white");
  }

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
      setHasValue(value.length > 0);
    },
    [setValue]
  );

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    // Add custom validation here...
    // if (required && !value) {
    //   return `${label} is required.`;
    // }
    if (validate) return validate(value);
  };

  const handleWheel = (ev, inputType) => {
    if (inputType === "number") {
      ev.currentTarget.blur(); // Prevent mouse wheel changing input value
    }
  };

  //#endregion

  //#region Side-effects

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult ?? false);
    if (setValid) setValid(!validationResult, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value]);

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (!defaultValue || !setInputValue || hasDefaultValueRef.current) return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
      hasDefaultValueRef.current = true;
    }
  }, [value, defaultValue, setInputValue]);

  //#endregion

  if (type === "hidden") {
    return (
      <input
        id={id}
        type={type}
        className={`block w-full tablet:text-sm ${
          theme ? theme : ""
        } ${inputClasses.join(" ")} ${
          touched && error && !focused ? inputErrorClasses.join(" ") : ""
        }`}
        value={value ?? ""}
        onBlur={() => {
          setTouched(true);
          setFocused(false);
        }}
        onChange={(ev) => setInputValue(ev.target.value)}
        onFocus={() => setFocused(true)}
        aria-invalid={error}
        disabled={disabled}
        autoComplete="off" // Prevent terrible chrome styling on autocompleted values
        data-cy="input"
        {...other}
      />
    );
  }

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${className} ${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
      }`}
    >
      {label && (
        <Label
          id={id}
          text={label}
          required={required}
          focused={focused}
          hasValue={hasValue}
          position={labelPosition}
          size={labelSize}
          addonPosition={addonLeft ? "left" : "right"}
          theme={theme}
          erroneous={touched && error && !focused}
        />
      )}
      <div className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}>
        <div className="relative">
          {addonLeft && (
            <Addon position="left" interactable={addonLeftInteractable}>
              {addonLeft}
            </Addon>
          )}
          <input
            id={id}
            type={type}
            className={`block w-full tablet:text-sm ${
              theme ? theme : ""
            } ${inputClasses.join(" ")} ${
              touched && error && !focused ? inputErrorClasses.join(" ") : ""
            }`}
            value={value ?? ""}
            onBlur={() => {
              setTouched(true);
              setFocused(false);
            }}
            onChange={(ev) => setInputValue(ev.target.value)}
            onFocus={() => setFocused(true)}
            onWheel={(ev) => handleWheel(ev, type)}
            aria-invalid={error}
            disabled={disabled}
            autoComplete="off" // Prevent terrible chrome styling on autocompleted values
            data-cy="input"
            {...other}
          />
          {addonRight && (
            <Addon position="right" interactable={addonRightInteractable}>
              {addonRight}
            </Addon>
          )}
          {touched && error && !focused && (
            <div
              className={`absolute -top-2 right-2 pointer-events-none px-1 ${errorIconClasses.join(
                " "
              )}`}
            >
              <ExclamationCircleIcon className="h-5 w-5 text-danger-500" />
            </div>
          )}
        </div>
        {touched && error && !focused ? (
          <p className="mt-2 text-xs text-danger-600" id="email-error">
            {error}
          </p>
        ) : (
          hint && (
            <p className="mt-2 text-xs text-gray-500" id="email-error">
              {hint}
            </p>
          )
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hint: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "number",
    "url",
    "date",
    "datetime-local",
    "month",
    "week",
    "time",
    "search",
    "tel",
    "checkbox",
    "radio",
    "hidden",
    "color",
  ]),
  size: PropTypes.oneOf(["sm"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  labelPosition: PropTypes.string,
  labelSize: PropTypes.oneOf(["large", "small", undefined]),
  addonLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  addonRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dependencies: PropTypes.array,
  render: PropTypes.bool,
  addonLeftInteractable: PropTypes.bool,
  addonRightInteractable: PropTypes.bool,
  enableCalcTrigger: PropTypes.bool,
};

export { Input };
