import React, { useContext, useEffect } from "react";
import Notification from "./core/Notifications/Notification";
import { NotificationContext } from "../context/NotificationProvider";

export default function Notifications() {
  const { notifications, removeNotification, clearNotifications } = useContext(
    NotificationContext
  );

  useEffect(() => {
    if (notifications) {
      const clearNotificationsTimeout = setTimeout(
        () => clearNotifications(),
        5000
      );
      return () => clearTimeout(clearNotificationsTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  if (!notifications) {
    return null;
  }

  const handleClose = (notificationId) => {
    removeNotification(notificationId);
  };

  return (
    <div>
      {notifications.map((item, index) => (
        <Notification
          {...item}
          onClose={() => handleClose(item.id)}
        />
      ))}
    </div>
  );
}
