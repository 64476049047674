import React from "react";
import { Label } from "components/core";
import { Button } from "./Button";

export function ButtonGroup({
  id,
  label,
  labelSize,
  labelPosition,
  required,
  listOptions,
  active,
  onClick,
}) {

  return (
    <div>
      {label && (
        <Label
          id={id}
          text={label}
          position={labelPosition}
          size={labelSize}
          required={required}
        />
      )}
      <div className="mt-2 relative z-0 inline-flex shadow-sm rounded-md">
        {listOptions?.map(({ Id, Text, Value, activeTheme, icon, ...other }) => (
          <Button
            // {...other}
            key={Id}
            aria-label={Text}
            theme={active === Value ? activeTheme : undefined}
            onClick={() => onClick(Value)}
            icon={icon}
          >
            {Text}
          </Button>
        ))}
      </div>
    </div>
  );
}
