import { useState, useEffect, useCallback, useRef } from "react";
import {
  Select,
  Text,
  Input,
  TextArea,
  Datepicker,
  Toggle,
  Upload,
  TimePicker,
  DateTimePicker,
  Html,
  MultiSelect,
} from "components/core";
import { ButtonGroup } from "components/core/Forms/ButtonGroup";
import {
  isNullEmptyOrWhitespace,
  isNull,
  isNumeric,
  parseJSON,
  parseListString,
} from "helpers/stringUtilities";
import { formValuesToObject, parseJsonLogic } from "helpers/formsUtilities";
import { AutoComplete } from "components/core";
import useDeepCompareEffect, {
  useDeepCompareEffectNoCheck,
} from "use-deep-compare-effect";
import { endOfFromDate, localDate } from "helpers/dateUtilities";
import NonConformanceReadonlyField from "components/forms/NonConformanceReadonlyField";
import classNames from "classnames";

/**
 * Component to render a form field.
 * @param {object} props
 * @returns {JSX} Returns JSX to render a form field.
 */
export default function FormField({
  field,
  penNumber,
  formValues: penFormValues = {},
  prevFormValues,
  setFieldValue,
  setFieldValid,
  validateField,
  standards,
  birdAge,
  house,
  startDate = null,
  endDate = null,
  groupFieldIndex = null,
  wrapperClassName = "",
  setIsCollapsible,
  collapsed,
  labelPosition,
  labelSize,
  ...other
}) {
  const fieldValue = penFormValues?.Values?.find(
    (fv) =>
      fv.Ref.toLowerCase() === field.Ref.toLowerCase() &&
      (isNullEmptyOrWhitespace(field.QuestionGroup) ||
        fv.QuestionGroup?.toLowerCase() === field.QuestionGroup.toLowerCase())
  );
  const FieldComponent = getFieldType(field);
  const inputType = getInputType(field.FieldType, field.Display);

  // DELETE testing data only
  // birdAge = { days: 100, weeks: 14.29 };

  //#region State

  const [standard, setStandard] = useState(undefined);
  const [size, setSize] = useState(undefined);
  const [render, setRender] = useState(field.render);
  const [listOptions, setListOptions] = useState([]);
  const [dependencies] = useState(field.Display?.edit?.dependencies);
  const [dependenciesFormValues, setDependenciesFormValues] =
    useState(undefined);

  const prevDependenciesFormValues = useRef(dependenciesFormValues);

  //#endregion

  //#region Callbacks

  const handleSetValue = (value) => {
    setFieldValue(field, penNumber, value);
  };

  const handleSetValid = (valid, value) => {
    const complete =
      isRequiredField() && isNullEmptyOrWhitespace(value) ? false : true;
    setFieldValid(field, penNumber, valid, complete, isRequiredField());
  };

  function isRequiredField() {
    if (penNumber.toString() === "1") {
      // Pen 1 fields are always required
      return field.Required.toLowerCase() === "d" ? true : false;
    }

    if (
      penFormValues?.BirdsAlive?.BirdsAlive > 0 ||
      penFormValues?.Values?.some((fv) => !!fv.Value.toString())
    ) {
      // pen has birds alive
      // pen contains a value
      return field.Required.toLowerCase() === "d" ? true : false;
    }

    return false;
  }

  /**
   * Get the label position based on the component name.
   * @param {string} fieldType
   * @returns {string}  The label position.
   */
  function getLabelPosition(componentName) {
    if (isNullEmptyOrWhitespace(componentName)) return;
    if (!isNullEmptyOrWhitespace(labelPosition)) return labelPosition;

    const fieldType = field.FieldType.toLowerCase();
    if (fieldType === "cf" || fieldType === "cfr") return "left";
    if (componentName === "NonConformanceReadonlyField") return "left";

    return "inset";
  }

  /**
   * Conditional build prop values to avoid passing unneccessary props
   */
  const getOptionalProps = (args) => {
    const optionalProps = {};
    if (FieldComponent.name === Datepicker.name) {
      // Datepicker component

      if (field.FieldType.toLowerCase() === "rdp") {
        // Restricted date picker
        const endOfToday = endOfFromDate(localDate(), "day");
        optionalProps.startDate = startDate;

        // Prevent future dates from being clicked
        if (!endDate || endDate.getTime() > endOfToday.getTime())
          optionalProps.endDate = endOfToday;
        else optionalProps.endDate = endDate;
      }
    } else if (FieldComponent.name === ButtonGroup.name) {
      // Button component
      // increase label size of first field in group
      optionalProps.labelSize = groupFieldIndex === 0 ? "large" : null;
      optionalProps.listOptions = args.listOptions;
    } else if (FieldComponent.name === Html.name) {
      // HTML component
      optionalProps.className = "text-sm";
      const color = field?.Display?.edit?.color?.toLowerCase();
      if (color === "error") {
        optionalProps.className += " text-danger-600";
      } else if (color === "warning") {
        optionalProps.className += " text-warning-600";
      } else if (color === "success") {
        optionalProps.className += " text-success-600";
      } else {
        optionalProps.className += " text-gray-500";
      }
    } else if ([MultiSelect.name, Select.name].includes(FieldComponent.name)) {
      // MultiSelect OR Select component
      optionalProps.listOptions = args.listOptions;
    } else if (FieldComponent.name === AutoComplete.name) {
      // AutoComplete
      optionalProps.suggestions = field?.ListOptions?.map((li) => li);
    } else if (FieldComponent.name === NonConformanceReadonlyField.name) {
      // NonConformanceReadonlyField
      optionalProps.formValue = fieldValue;
      optionalProps.field = field;
      optionalProps.severityColour = field.ListOptions?.find(
        (li) => li.Value === fieldValue?.Value
      )?.SeverityColour;
    } else if (FieldComponent.name === Input.name) {
      // Input
      if (field.FieldType.toLowerCase() === "f") {
        optionalProps.step = "any";
      }
    }

    return optionalProps;
  };

  const handleSetCollapsible = useCallback(
    (collapsed) => {
      if (!setIsCollapsible) return; // Not grouped

      setIsCollapsible(field.Ref, collapsed);
    },
    [setIsCollapsible, field?.Ref]
  );

  //#endregion

  //#region Side-effects

  /**
   * Set standard
   */
  useEffect(() => {
    if (!field?.Std || !standards?.length || !birdAge?.days) return;

    setStandard(
      standards.find(
        (s) =>
          s.ID.toString() === field.Std.toString() &&
          s.Days.toString() === birdAge.days.toString()
      )
    );
  }, [standards, birdAge?.days, field?.Std]);

  /**
   * Set field size
   */
  useEffect(() => {
    if (isNull(field)) return;

    if (field.Display?.edit?.size) {
      setSize(field.Display?.edit.size);
    }
  }, [field]);

  /**
   * Initially set collapsible & collapsed
   */
  useDeepCompareEffectNoCheck(() => {
    if (!handleSetCollapsible) return;
    // Display
    // Collapsed
    const collapsed = field.Display?.edit?.collapsed;

    const formValuesAsObj = formValuesToObject(
      !isNullEmptyOrWhitespace(penFormValues) ? [penFormValues] : []
    );
    const parsedCollapsed = parseJsonLogic(parseJSON(collapsed), {
      this: !isNullEmptyOrWhitespace(fieldValue?.Value)
        ? fieldValue.Value
        : null,
      ...formValuesAsObj,
    });

    if (typeof parsedCollapsed == "boolean" || parsedCollapsed === null) {
      handleSetCollapsible(parsedCollapsed);
    }
  }, [
    field?.Display,
    field?.Ref,
    penFormValues,
    handleSetCollapsible,
    fieldValue,
  ]);

  /**
   * Set render
   */
  useDeepCompareEffectNoCheck(() => {
    if (isNull(field?.Ref)) return;

    const isRenderedField = () => {
      let _render = field.render;
      // console.log("isRenderedField", field.Ref, _render);
      if (_render === false) {
        // Respect hidden elements set in parent(s)
        setRender(_render);
        return;
      }

      // Display
      // Position
      const position = field.Display?.edit?.position;

      const formValuesAsObj = formValuesToObject(
        !isNullEmptyOrWhitespace(penFormValues) ? [penFormValues] : []
      );
      const prevFormValuesAsObj = formValuesToObject(prevFormValues?.PenValues);
      const parsedPosition = parseJsonLogic(parseJSON(position), {
        // this: !isNullEmptyOrWhitespace(fieldValue?.Value)
        //   ? fieldValue.Value
        //   : null,
        previous: prevFormValuesAsObj,
        ...formValuesAsObj,
      });

      if (field.Display?.toString()?.toLowerCase() === "h") {
        // { "Display": "h" }
        _render = false;
      } else if (parsedPosition === null) {
        // { "Display": { "edit": { "position" : null }}}
        _render = false;
      } else if (
        isNumeric(parsedPosition) ||
        isNullEmptyOrWhitespace(parsedPosition)
      ) {
        // { "Display": { "edit": { "position" : 1 }}}
        _render = true;
      }

      // Collapsed
      if (_render && collapsed === true) {
        // Collapsed, don't continue to check position
        _render = false;
      }

      setRender(_render);
    };

    isRenderedField();
  }, [
    field.Display,
    field.render,
    field.Ref,
    penFormValues,
    collapsed,
    fieldValue?.Value,
    prevFormValues,
  ]);

  /**
   * Set list options
   */
  useDeepCompareEffect(() => {
    const prepareListOptions = (penFormValues) => {
      if (isNullEmptyOrWhitespace(field.List))
        throw new Error("field.List is a required prop");

      if (isNullEmptyOrWhitespace(other.farm))
        throw new Error("Farm is a required prop");

      const _parsedListOptions = parseListString(field.List, other.farm);
      const _fieldListOptions = [...field.ListOptions, ..._parsedListOptions];

      if (isNullEmptyOrWhitespace(_fieldListOptions)) return [];

      // Remove empty list options
      let result = _fieldListOptions.filter(
        (li) =>
          !isNullEmptyOrWhitespace(li.Id) &&
          !isNullEmptyOrWhitespace(li.Text) &&
          !isNullEmptyOrWhitespace(li.Value)
      );

      if (FieldComponent.name === Select.name) {
        // Select component
        // Filter by dependencies
        const dependencies = field.Display?.edit?.dependencies;
        if (
          !isNullEmptyOrWhitespace(dependencies) &&
          dependencies instanceof Array
        ) {
          // Get dependencies form values
          const _dependenciesFormValues = []; // []
          // Iterate through dependencies
          for (const dependency of dependencies) {
            // Find matching pen form value
            const matchingFormValue = penFormValues?.Values?.find(
              (v) => v.Ref.toLowerCase() === dependency.toLowerCase()
            );
            // Match found and dependency is unique in array
            if (
              !isNullEmptyOrWhitespace(matchingFormValue) &&
              _dependenciesFormValues[dependency] === undefined
            ) {
              _dependenciesFormValues[dependency] =
                matchingFormValue.Value.toString().toLowerCase();
              // console.log(dependency, dependenciesFormValues[dependency], matchingFormValue.Value.toString().toLowerCase())
            } else {
              // Add dependency without form value to prevent rendered options until selection is made
              _dependenciesFormValues[dependency] = null;
            }
          }

          // Set dependency form values to allow use to track if a value has changed
          setDependenciesFormValues(_dependenciesFormValues);

          // Filter list options by dependency form values
          result = result.filter((li) => {
            if (isNullEmptyOrWhitespace(li.Parent)) return true;

            // Does list option parent match dependency form value
            const dependencyValues = Object.values(_dependenciesFormValues);
            if (dependencyValues.includes(li.Parent.toString())) {
              // Exists in dependency values
              return true;
            }
            return false;
          });
        }
      }

      return result;
    };

    if (["dd", "mdd", "bg"].includes(field?.FieldType?.toLowerCase())) {
      const _listOptions = prepareListOptions(penFormValues);
      setListOptions(_listOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [penFormValues]);

  /**
   * Reset value when a dependency form value changes
   */
  useEffect(() => {
    if (isNullEmptyOrWhitespace(dependenciesFormValues)) return;

    const parentFormValuesHaveChanged = hasDependenciesFormValuesChanged();
    if (parentFormValuesHaveChanged) handleSetValue(null);

    prevDependenciesFormValues.current = dependenciesFormValues;

    function hasDependenciesFormValuesChanged() {
      if (isNullEmptyOrWhitespace(prevDependenciesFormValues.current))
        return false;

      // console.log("dependenciesFormValues changed:", prevDependenciesFormValues.current, dependenciesFormValues);
      const parentValueChanged = Object.entries(
        prevDependenciesFormValues.current
      )?.some(([prevRef, prevValue]) => {
        // console.log("dependenciesFormValues changed:", dependenciesFormValues[prevRef], prevValue);
        // console.log("dependenciesFormValues changed:", isNull(prevValue), prevValue !== dependenciesFormValues[prevRef]);
        if (
          !isNull(prevValue) &&
          prevValue !== dependenciesFormValues[prevRef]
        ) {
          // Had a value and has now changed
          return true;
        }

        return false;
      });
      // console.log("dependenciesFormValues changed parentValueChanged:", parentValueChanged);

      return parentValueChanged;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependenciesFormValues]);

  //#endregion

  const fieldComponent = (
    <FieldComponent
      id={`${field.Ref}-${field.Level}${penNumber}`}
      key={`${field.Ref}-${field.Level}${penNumber}`}
      label={field.Name}
      hint={field.Description}
      type={inputType}
      labelPosition={getLabelPosition(FieldComponent.name)}
      labelSize={labelSize}
      value={fieldValue?.Value}
      setValue={handleSetValue}
      setValid={handleSetValid}
      validate={(value) => validateField(field, value)}
      required={isRequiredField()}
      addonLeft={field.Prefix}
      addonRight={field.Suffix}
      defaultValue={field.DefaultValue}
      dependencies={dependencies}
      // size={size}
      render={render}
      // hint={`Ref: ${field.Ref}. Calculation: ${field.Calculation}.`}
      {...getOptionalProps({ listOptions })}
    />
  );

  // Only render FieldComponent Wrapper if field is rendered
  return (
    <FieldComponentWrapper
      size={size}
      standard={standard}
      inputType={inputType}
      className={classNames(render === false && "hidden", wrapperClassName)}
    >
      {fieldComponent}
    </FieldComponentWrapper>
  );
}

/**
 * Get the component input type based on the field type.
 * @param {object} field
 * @returns {string}  The label position.
 */
function getFieldType(field) {
  const fieldType = !!field.FieldType
    ? field.FieldType.toLowerCase()
    : field.FieldType;

  // Handle special cases
  if (field.Readonly) {
    // Non-Conformance readonly field{
    return NonConformanceReadonlyField;
  }

  switch (fieldType) {
    case "ac": // Autocomplete
      return AutoComplete;
    case "up": // Upload
      return Upload;
    case "cb": // Checkbox
      return Toggle;
    case "cf": // Calculated field
    case "cfr": // Calculated field
      return Text;
    case "dd": // Dropdown
      return Select;
    case "mdd": // Multi-select Dropdown
      return MultiSelect;
    case "bg": // Button Group
      return ButtonGroup;
    case "sl": // Comment
      return TextArea;
    case "dp": // Datepicker
    case "rdp": // Restricted Datetimepicker
      return Datepicker;
    case "dtp": // Datetimepicker
      return DateTimePicker;
    case "t":
      return TimePicker;
    case "tx": // HTML text
      return Html;
    case "f": // Float
    case "i": // Integer
    default:
      return Input;
  }
}

/**
 * Get the textbox input type based on the field type.
 * @param {string} fieldType
 * @returns {string}  The label position.
 */
function getInputType(fieldType, display) {
  fieldType = !!fieldType ? fieldType.toLowerCase() : fieldType;

  // Override with display value
  if (typeof display == "string" && display?.toLowerCase() === "h")
    return "hidden";

  switch (fieldType) {
    case "f":
    case "i":
      return "number";
    case "h":
      return "hidden";
    case "s":
      return "text";
    default:
      return undefined;
  }
}

/**
 *
 * @param {object} props
 * @returns
 */
function FieldComponentWrapper({
  size,
  standard,
  children,
  inputType,
  className = "",
  ...other
}) {
  if (inputType === "hidden") return children;

  className = `${className} ${getColSpan()} grid grid-cols-3 gap-2 items-center`;

  function getColSpan() {
    if (size === "small") {
      return "col-span-2";
    } else if (size === "xsmall") {
      return "col-span-1";
    } else if (size === "medium") {
      return "col-span-3";
    } else {
      return "col-span-4";
    }
  }

  return (
    <div {...other} className={className}>
      <div className={!!standard ? "col-span-2" : "col-span-3"}>{children}</div>
      {!!standard && (
        <div className="col-span-1">
          <Text
            id={`${standard.ID}-${standard.StdNo}-${standard.Days}`}
            label={standard.StdName}
            value={standard.Value}
            labelPosition="inset"
          />
        </div>
      )}
    </div>
  );
}
