import equal from "fast-deep-equal/es6/react";

export function isEqual(a, b) {
  // Objects - deep compare
  if (typeof a == "object" && typeof b == "object") {
    return equal(a, b);
  }
  
  // Strings
  return a === b;
}