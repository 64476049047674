import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import DynamicChart from "components/Charts/DynamicChart";
import { dateToISO } from "helpers/dateUtilities";
import { formatChartData } from "helpers/dashboardUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities.js";
import useQuery from "hooks/useQuery.jsx";
import { AppDataContext } from "context/AppDataProvider";
import Widget from "components/Dashboard/Widget.jsx";
import { LOADED_STATUS } from "constants.js";
import { NotificationContext } from "context/NotificationProvider";

export default function Dashboard({ id: dashboardId }) {
  const query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const { farms } = useContext(AppDataContext);

  const [dashboard, setDashboard] = useState({ title: "", charts: [] });
  const [chartsData, setChartsData] = useState([]);
  const [loaded, setLoaded] = useState(LOADED_STATUS.LOADING);

  const abortControllerRef = useRef(undefined);

  const { addNotification } = useContext(NotificationContext);

  //#region Callbacks

  const fetchCharts = useCallback(async () => {
    if (
      !farmId ||
      !houseId ||
      isNullEmptyOrWhitespace(dashboardId) ||
      isNullEmptyOrWhitespace(farms)
    )
      return [];

    // Clear previous values
    setDashboard({ title: "", charts: [] });

    // Charts
    const _dashboards = await fetchDashboardWithCharts();

    // At present, only use a single dashboard
    if (_dashboards.length > 0) {
      const _dashboard = _dashboards[0]; // Get first

      // Fetch dashboard data
      const _dataSources = _dashboard.dataSources.split(",");
      const _reports = await Promise.all(
        _dataSources.map((dataSource) => fetchData(dataSource))
      );

      const _flattenedReports = _reports.flat()?.filter(report => report !== undefined);

      // Sort by position, desc
      _dashboard?.charts?.sort((a, b) => a.position - b.position);

      const _charts = _dashboard?.charts ?? [];

      // Convert to metrics
      for (const chart of _charts) {
        // Add chart
        addChartData(chart, _flattenedReports);
      }

      // Set dashboard
      setDashboard(_dashboard);
    }

    /**
     * Fetch dashboard & charts
     */
    async function fetchDashboardWithCharts() {
      const { signal } = abortControllerRef.current;
      try {
        const response = await fetch(`/api/dashboard-get?id=${dashboardId}`, {
          signal,
          method: "GET",
        });
        if (response.ok) {
          const responseData = await response.json();
          setLoaded(LOADED_STATUS.LOADED);

          return responseData ?? [];
        } else {
          setLoaded(LOADED_STATUS.ERROR);

          addNotification({
            title: "Error",
            theme: "error",
            description: "An error occurred while fetching dashboard.",
          });
        }
      } catch (err) {
        if (signal.aborted) return [];
        console.error(err);

        setLoaded(LOADED_STATUS.ERROR);

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching dashboard.",
        });
      }

      return [];
    }

    /**
     * Fetch data
     */
    async function fetchData(dataSources) {
      const { signal } = abortControllerRef.current;

      if (isNullEmptyOrWhitespace(dataSources))
        throw Error("No data sources provided.");

      // const dates = getLastXDays(60); // month
      // const fromDate = dateToISO(dates[dates.length - 1].getTime());
      // const toDate = dateToISO(dates[0].getTime());
      const farm = farms.find(
        (farm) => farm.FarmCode.toLowerCase() === farmId.toLowerCase()
      );
      const house = farm.Houses.find(
        (h) => h.HouseNumber.toString() === houseId.toString()
      );
      const cropDate = house?.Pens?.find(
        (p) => p.PenNumber.toString() === "1"
      ).Placement?._CropDate?.normalised;
      const cropDateFormatted = dateToISO(cropDate?.getTime());

      try {
        const response = await fetch(
          // `/api/chartdata-get?farmId=${farmId}&toDate=${toDate}&fromDate=${fromDate}`,
          `/api/chartdata-get?farmId=${farmId}&cropDate=${cropDateFormatted}&house=${houseId}&dataSource=${dataSources}`,
          {
            signal,
            method: "GET",
          }
        );
        if (response.ok) {
          const responseData = await response.json();

          return responseData;
        } else {
          setLoaded(LOADED_STATUS.ERROR);

          addNotification({
            title: "Error",
            theme: "error",
            description: "An error occurred while fetching dashboard data.",
          });
        }
      } catch (err) {
        if (signal.aborted) return;
        console.error(err);

        setLoaded(LOADED_STATUS.ERROR);

        addNotification({
          title: "Error",
          theme: "error",
          description: "An error occurred while fetching dashboard data.",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, farms, houseId, dashboardId]);

  const addChartData = (chart, reports) => {
    // Build data
    setChartsData((prevState) => {
      const newState = prevState.filter((cd) => cd.id !== chart.id);
      const _chartData = formatChartData(reports, chart);
      newState.push({ id: chart.id, data: _chartData });

      return newState;
    });
  };

  //#endregion

  //#region Side-effects

  /**
   * Mount/Unmount
   */
  useEffect(
    () => {
      abortControllerRef.current = new AbortController();

      return () => {
        abortControllerRef.current.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Fetch data
   */
  useEffect(() => {
    setLoaded(LOADED_STATUS.LOADING);
    fetchCharts();
  }, [fetchCharts]);

  //#endregion

  // const metricCharts = charts.filter((x) => x.type === CHART_TYPE.METRIC);
  // const otherCharts = charts.filter((x) => x.type !== CHART_TYPE.METRIC);

  return (
    <div className="grid gap-4 grid-cols-12">
      {loaded === LOADED_STATUS.LOADED ? (
        dashboard.charts.map((chart) => (
          <Widget
            key={chart.id}
            title={chart.title}
            className={"relative"}
            showHeader={chart.showHeader}
            size={chart?.settings?.widgetSize}
          >
            <DynamicChart
              key={chart.id}
              chart={chart}
              data={chartsData?.find((cd) => cd.id === chart.id)?.data}
            />
          </Widget>
        ))
      ) : (
        <div className="animate-pulse h-6 w-2/3 bg-gray-400 rounded-full"></div>
      )}
    </div>
  );
}
