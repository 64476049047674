import React from "react";

export default function PageHeaderSkeleton({ className }) {
  return (
    <header className={`bg-white shadow-sm ${className}`}>
      <div className="animate-pulse">
        <div className="w-56 h-4 bg-gray-300 rounded"></div>
      </div>
    </header>
  );
}
