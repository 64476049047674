import { Suspense, lazy } from "react";
import { Button } from "components/core";
import {
  CheckCircleIcon,
  EditCircleIcon,
  ExclamationCircleIcon,
} from "assets/icons";
import { DATA_STATUS } from "constants.js";

const HorizontalProgress = lazy(() =>
  import("components/Progress/HorizontalProgress")
);

/**
 * Component to render form buttons.
 * @param {object} props
 * @returns {JSX} Returns JSX to render form buttons.
 */
 const FormButtons = ({
  _ref,
  dataStatus,
  loaded,
  handleClickCancel = null,
  showDraft = false,
  requiredFieldCount,
  requiredFieldCompletedCount,
  className = "",
  size,
  button,
  ...other
}) => {
  const showProgress = !!requiredFieldCount ? true : false;
  return (
    <>
      <div ref={_ref}></div> {/* Sticky limit */}
      <div {...other} className={`${className}`}>
        <div className="grid grid-cols-6 gap-1 px-4 items-center">
          <div className="col-span-6">{button}</div>

          {showProgress && (
            <div className="col-span-6 flex flex-col items-center">
              <Suspense
                fallback={
                  <div>
                    {0}/{0}
                  </div>
                }
              >
                <HorizontalProgress
                  value={
                    (requiredFieldCompletedCount / requiredFieldCount) * 100
                  }
                  text={`${requiredFieldCompletedCount}/${requiredFieldCount}`}
                />
              </Suspense>
            </div>
          )}

          <div className="col-span-6">
            <div className="flex space-x-2 justify-end tablet:px-0">
              <Button type="button" onClick={handleClickCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                theme={
                  dataStatus === DATA_STATUS.COMPLETE
                    ? "primary"
                    : dataStatus === DATA_STATUS.ERROR
                    ? "danger"
                    : null
                }
                disabled={
                  dataStatus === DATA_STATUS.ERROR ||
                  (dataStatus !== DATA_STATUS.COMPLETE && !showDraft)
                }
                className=""
                optionsContainerClassName=""
                icon={
                  dataStatus === DATA_STATUS.COMPLETE ? (
                    <CheckCircleIcon className="h-5 w-5 mr-1 text-primary-light group-hover:text-primary-light" />
                  ) : dataStatus === DATA_STATUS.ERROR ? (
                    <ExclamationCircleIcon className="h-5 w-5 mr-1 text-danger-400 group-hover:text-gray-400" />
                  ) : showDraft ? (
                    <EditCircleIcon className="h-5 w-5 mr-1 text-gray-400 group-hover:text-gray-400" />
                  ) : (
                    <CheckCircleIcon className="h-5 w-5 mr-1 text-gray-300" />
                  )
                }
              >
                {dataStatus === DATA_STATUS.COMPLETE
                  ? `Save`
                  : dataStatus === DATA_STATUS.ERROR
                  ? `Invalid Data`
                  : showDraft
                  ? `Save Draft`
                  : `Save`}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormButtons;