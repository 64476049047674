import React, { useState, createContext, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

export const NotificationContext = createContext(undefined);

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(({ key, ...other } = {}) => {
    key = !key ? uuidv4() : key; // generate unique id
    setNotifications(prevState => [...prevState, { key, ...other }]);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications(prevState => [
      ...prevState.filter((notification) => notification.id !== id),
    ]);
  }, []);

  function clearNotifications() {
    if (!notifications || notifications.length < 1) return;

    setNotifications([]);
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        clearNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
